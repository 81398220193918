import { Spin } from "antd";
import { Col } from "antd/lib/grid";
import Modal from "antd/lib/modal";
import Radio from "antd/lib/radio";
import Row from "antd/lib/row";
import Tabs from "antd/lib/tabs";
import Tag from "antd/lib/tag";
import { TrackJS } from "trackjs";
import useApi from "api";
import { ReactComponent as CheckUpActive } from "assets/icons/checkUpActive.svg";
import { ReactComponent as CheckUpStopped } from "assets/icons/checkUpStopped.svg";
import { ReactComponent as CloseOutlined } from "assets/icons/closeOutlined.svg";
import { ReactComponent as SponsoredBrandActive } from "assets/images/sponsored-brand-active.svg";
import { ReactComponent as SponsoredBrandSoon } from "assets/images/sponsored-brand-soon.svg";
import { ReactComponent as SponsoredDisplayActive } from "assets/images/sponsored-display-active.svg";
import { ReactComponent as SponsoredDisplaySoon } from "assets/images/sponsored-display-soon.svg";
import { ReactComponent as SponsoredDisplayWithProductActive } from "assets/images/sponsored-display-with-product-active.svg";
import { ReactComponent as SponsoredDisplayWithProductSoon } from "assets/images/sponsored-display-with-product-soon.svg";
import { ReactComponent as SponsoredProductActive } from "assets/images/sponsored-product-active.svg";
import { ReactComponent as SponsoredProductSoon } from "assets/images/sponsored-product-soon.svg";
import { ReactComponent as SponsoredStoreActive } from "assets/images/sponsored-store-active.svg";
import { ReactComponent as SponsoredStoreSoon } from "assets/images/sponsored-store-soon.svg";
import { ReactComponent as SponsoredVideoActive } from "assets/images/sponsored-video-active.svg";
import { ReactComponent as SponsoredVideoSoon } from "assets/images/sponsored-video-soon.svg";
import { ReactComponent as SponsoredVideoWithProductActive } from "assets/images/sponsored-video-with-product-active.svg";
import { ReactComponent as SponsoredVideoWithProductSoon } from "assets/images/sponsored-video-with-product-soon.svg";
import cc from "classcat";
import RetailMainButton from "components/Button/RetailMainButton";
import RetailDateColumn from "components/Column/RetailDateColumn";
import RetailMoneyColumn from "components/Column/RetailMoneyColumn";
import RetailNameColumn from "components/Column/RetailNameColumn";
import RetailNumberColumn from "components/Column/RetailNumberColumn";
import RetailPercentageColumn from "components/Column/RetailPercentageColumn";
import RetailStateColumn from "components/Column/RetailStateColumn";
import RetailStatusColumn from "components/Column/RetailStatusColumn";
import CampaignDetailsModal from "components/Modal/CampaignDetailsModal";
import RetailNotification from "components/Notification";
import AdvertiserSelect from "components/Select/AdvertiserSelect";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";
import { Auth, AuthContext } from "context/AuthProvider";
import { useCurrency } from "context/CurrencyProvider";
import useConfigSchema from "hooks/useConfigSchema";
import useWidget from "hooks/useWidget";
import introJs from "intro.js";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { marketplaceName } from "utils/defaults";
import { campaignFilters } from "utils/filters";
import { CampaignType } from "utils/types";

import { LinkOutlined } from "@ant-design/icons";

import RetailTable from "../RetailTable";
import cm from "./style.module.scss";

export interface CampaignTypeProps {
  t: TFunction;
  campaign_type: CampaignType;
}

const switchSVGForCampaignType = (campaign_type: CampaignType) => {
  const isActive = campaign_type.value === "ACTIVE";
  switch (campaign_type.config_schema_name) {
    case "sponsored_product_availability":
      return isActive ? <SponsoredProductActive /> : <SponsoredProductSoon />;
    case "sponsored_brand_availability":
      return isActive ? <SponsoredBrandActive /> : <SponsoredBrandSoon />;
    case "sponsored_store_availability":
      return isActive ? <SponsoredStoreActive /> : <SponsoredStoreSoon />;
    case "sponsored_display_availability":
      return isActive ? <SponsoredDisplayActive /> : <SponsoredDisplaySoon />;
    case "sponsored_video_availability":
      return isActive ? <SponsoredVideoActive /> : <SponsoredVideoSoon />;
    case "sponsored_display_with_product_availability":
      return isActive ? (
        <SponsoredDisplayWithProductActive />
      ) : (
        <SponsoredDisplayWithProductSoon />
      );
    case "sponsored_video_with_product_availability":
      return isActive ? (
        <SponsoredVideoWithProductActive />
      ) : (
        <SponsoredVideoWithProductSoon />
      );
    default:
      break;
  }
};

const CampaignTypesSoonRow = ({ t, campaign_type }: CampaignTypeProps) => {
  return (
    <Row className={cm.card}>
      <Col className={cm.imgContainer}>
        {switchSVGForCampaignType(campaign_type)}
      </Col>
      <Col className={cm.textContainer}>
        <RetailTitle className={cm.cardTitle}>
          {t(
            `components.modal.selectCampaign.${campaign_type.config_schema_name}_title`
          )}
          <Tag className={cm.tag}>
            {t("components.modal.selectCampaign.soon")}
          </Tag>
        </RetailTitle>
        <RetailText className={cm.cardText} size="xxxs" weight="medium">
          {t(
            `components.modal.selectCampaign.${campaign_type.config_schema_name}_text`
          )}
        </RetailText>
      </Col>
    </Row>
  );
};

const CampaignTypesActiveRow = ({ t, campaign_type }: CampaignTypeProps) => {
  const switchValue = () => {
    switch (campaign_type.config_schema_name) {
      case "sponsored_product_availability":
        return "PRODUCT";
      case "sponsored_brand_availability":
        return "BRAND";
      case "sponsored_display_availability":
        return "DISPLAY";
      case "sponsored_store_availability":
        return "STORE";
      case "sponsored_video_availability":
        return "VIDEO";
      case "sponsored_display_with_product_availability":
        return "DISPLAY_WITH_PRODUCT";
      case "sponsored_video_with_product_availability":
        return "VIDEO_WITH_PRODUCT";
      default:
        break;
    }
  };

  const linkVisibility =
    marketplaceName === "koçtaş" &&
    campaign_type.config_schema_name === "sponsored_display";

  const koctasInformativeLink =
    "https://stsupplierplatformprod.blob.core.windows.net/reklam-alanlari/Koctas-Gorsel-Reklamlar.pdf";

  const informativeLink = () => {
    switch (marketplaceName) {
      case "koçtaş":
        return {
          link: koctasInformativeLink,
          text: "koctasLink",
        };
      default:
        break;
    }
  };

  return (
    <Radio className={cm.radio} value={switchValue()} data-cy={switchValue()}>
      <Row className={cm.row} id="campaign-type-modal">
        <Col className={cm.imgContainer}>
          {switchSVGForCampaignType(campaign_type)}
        </Col>
        <Col className={cm.textContainer}>
          <RetailTitle className={cc([cm.radioTitle])}>
            {t(
              `components.modal.selectCampaign.${campaign_type.config_schema_name}_title`
            )}
          </RetailTitle>
          <RetailText className={cm.radioText} size="xxxs" weight="medium">
            {t(
              `components.modal.selectCampaign.${campaign_type.config_schema_name}_text`
            )}
          </RetailText>
          {linkVisibility ? (
            <a
              target="_blank"
              rel="noreferrer"
              href={informativeLink()?.link}
              className={`flex ${cm.learnMore}`}
            >
              <LinkOutlined />
              {t(`components.modal.selectCampaign.${informativeLink()?.text}`)}
            </a>
          ) : null}
        </Col>
      </Row>
    </Radio>
  );
};

const RetailCampaignTable = () => {
  const { t } = useTranslation();

  const { api, adminInGeneral, role, baseURL, isLimitedUser } = useApi();

  const { currencySymbol } = useCurrency();

  const { showAdminBar } = useContext(AuthContext) as Auth;

  const { selectedWidgets: data, changeValue } = useWidget("tour", "", "");

  const renderColumns = (col: string, value: any, records: any) => {
    switch (col) {
      case "status":
        return <RetailStatusColumn records={records} url={tableConfig.url} />;
      case "campaign_name":
        return (
          <RetailNameColumn
            state={{
              ad_type: records.ad_type,
              breadcrumb: true,
              breadcrumbType: "campaign",
              value: value,
            }}
            to={
              admin
                ? `/campaign/${records.id}?adv=${records.advertiser_id}`
                : `/campaign/${records.id}`
            }
            value={`${value} (${records.id})`}
          />
        );
      case "advertiser_name":
        return (
          <RetailNameColumn
            to={`/campaigns?adv=${records.advertiser_id}`}
            value={`${value} (${records.advertiser_id})`}
          />
        );
      case "ad_type":
        return marketplaceName.toLowerCase() === "toters" && value !== "DISPLAY"
          ? t("pages.acc.campaigns.table.store")
          : t(`pages.acc.campaigns.table.${value.toLowerCase()}`);
      case "start_date":
      case "end_date":
        return <RetailDateColumn value={value} />;
      case "state":
        return isCheckupView ? (
          <>
            {value === "ON_PUBLISH" ? (
              <RetailText
                weight="medium"
                family="poppins"
                size="xxxs"
                className={cm.onPublishText}
              >
                <CheckUpActive /> {t("common.table.on")}
              </RetailText>
            ) : (
              <RetailText
                weight="bold"
                family="poppins"
                size="xxxs"
                className={cm.stoppedText}
              >
                <CheckUpStopped />
                {t(`components.states.campaigns_${value.toLowerCase()}`)}
              </RetailText>
            )}
          </>
        ) : (
          <RetailStateColumn value={value} type="campaigns" />
        );
      case "budget_type":
        return (
          <div className={cm.budget}>
            <RetailText family="poppins" size="xs">
              {t(`pages.acc.campaigns.table.${value?.toLowerCase()}`)}
            </RetailText>
            <RetailMoneyColumn
              value={records?.daily_budget || records?.total_budget}
            />
            {records.budget_type === "FLEXIBLE" && (
              <>
                <RetailText
                  family="poppins"
                  size="xs"
                  className={cm.flexibleBudgetText}
                >
                  -
                </RetailText>
                <RetailText
                  family="poppins"
                  size="xs"
                  className={cm.flexibleBudgetText}
                >
                  {t("pages.acc.campaigns.table.budgetLimit", {
                    value: `${currencySymbol} ${records?.total_budget}`,
                  })}
                </RetailText>
              </>
            )}
          </div>
        );
      case "impressions":
      case "clicks":
      case "viewable_impressions":
      case "sale":
      case "RoAS":
      case "direct_sale":
      case "indirect_sale":
      case "video_start":
      case "video_complete":
        return (
          <RetailNumberColumn
            value={value}
            isZero={col === "video_start" || col === "video_complete"}
          />
        );
      case "CTR":
      case "CVR":
      case "ACoS":
      case "VCR":
        return <RetailPercentageColumn value={value} />;
      case "CPM":
      case "CPC":
      case "sale_amount":
      case "direct_sale_amount":
      case "indirect_sale_amount":
        return <RetailMoneyColumn value={value} />;
      case "bid_type":
        return t(`pages.acc.campaigns.table.${value.toLowerCase()}`);
      case "spend":
        return <RetailMoneyColumn value={value} spendColumn={true} />;
      case "spending_type":
        return t(`components.campaignForm.firstStep.${value?.toLowerCase()}`);
      case "created_by":
      case "updated_by":
        return value && value !== "NaT" ? value : "-";
      default:
        return value ? value : "-";
    }
  };

  const location = useLocation();

  const admin = location.pathname.includes("admin");

  let navigate = useNavigate();

  const [visible, setVisible] = useState(false);

  const [adminVisible, setAdminVisible] = useState(false);

  const [advertiser, setAdvertiser] = useState<any>(null);

  const [activeKey, setActiveKey] = useState("ACTIVE");

  const [adType, setAdType] = useState("");

  const queryClient = useQueryClient();

  const [isCheckupView, setIsCheckupView] = useState(false);

  const {
    data: config,
    isLoading,
    error,
  } = useConfigSchema(visible, advertiser?.id, "campaign_type_availability");

  useLayoutEffect(() => {
    if (isCheckupView) setActiveKey("CHECKUP_ACTIVE");
    else setActiveKey("ACTIVE");
  }, [isCheckupView]);

  const openCheckUpView = () => {
    setIsCheckupView(true);
    adminInGeneral
      ? localStorage.removeItem("admin_campaigns_page")
      : localStorage.removeItem("user_campaigns_page");
  };

  const closeCheckUpView = () => setIsCheckupView(false);

  const updateValue = async (data: any) => {
    const config = {
      status: data.status === "ARCHIVED" ? "ACTIVE" : "ARCHIVED",
    };

    try { 
      const response = await api.patch(`campaigns/${data.id}`, config);
      return response;
    } catch (error) {
      TrackJS.track({
        message: "Campaign Patch Error",
        metadata: {
          endpoint: `campaigns/${data.id}`,
          requestBody: config,
          role,
          isLimitedUser: `Is user limited? ${isLimitedUser}`,
          baseURL: baseURL(),
          responseBody: error.response?.data,
          statusCode: error.response?.status,
          errorMessage: error.message,
          severity: "Medium"
        },
      });
    }
  };

  const { mutateAsync } = useMutation(updateValue);

  const changeArchiveStatus = async (data: any) => {
    try {
      await mutateAsync(data);
      queryClient.refetchQueries("table");
      RetailNotification.showNotification(
        "success",
        "",
        t(`components.notification.${data.status.toLowerCase()}Campaign`)
      );
    } catch (error) {
      RetailNotification.showNotification(
        "error",
        "",
        t("components.notification.statusError")
      );
    }
  };

  const copyTheCampaign = async (data: any) => {
    const response = await api.post(`campaigns/${data.id}/duplicate`);
    return response;
  };

  const { mutateAsync: copyMutate } = useMutation(copyTheCampaign);

  const copy = async (data: any) => {
    try {
      await copyMutate(data);
      queryClient.refetchQueries("table");
      RetailNotification.showNotification(
        "success",
        "",
        t("components.notification.copiedCampaign")
      );
    } catch (error) {
      RetailNotification.showNotification(
        "error",
        "",
        t("components.notification.copyError")
      );
    }
  };

  const tableConfig = {
    url: "campaigns",
    isRelation: false,
    filters: campaignFilters(t, admin),
    activeKey,
    to: "campaign",
    state: true,
    onArchive: changeArchiveStatus,
    onThirdOption: copy,
    renderColumns,
  };

  const activeTabConfig = {
    ...tableConfig,
    defaultFilter: [{ key: "campaignStatus", op: "ne", value: "ARCHIVED" }],
  };

  const archivedTabConfig = {
    ...tableConfig,
    defaultFilter: [{ key: "campaignStatus", op: "eq", value: "ARCHIVED" }],
  };

  useEffect(() => setAdvertiser(location.state), []);

  const handleClick = () => {
    admin ? setAdminVisible(true) : setVisible(true);
  };

  const handleAdvertiserNavigate = () =>
    navigate(
      { pathname: "/create-campaign", search: location.search },
      {
        state: {
          advertiser: advertiser,
          ad_type: adType === "" ? "PRODUCT" : adType,
        },
      }
    );

  const handleAdvertiserSelect = () => {
    if (!advertiser) {
      RetailNotification.showNotification(
        "error",
        t("pages.acc.campaigns.errorTitle"),
        t("pages.acc.campaigns.errorText")
      );
      return;
    }
    setAdminVisible(false);
    setVisible(true);
  };

  const handleNavigateAdmin = () => {
    if (advertiser !== null) {
      navigate("/admin/create-campaign", {
        state: {
          id: advertiser.id,
          name: advertiser.name,
          ad_type: adType === "" ? "PRODUCT" : adType,
        },
      });
    } else {
      RetailNotification.showNotification(
        "error",
        t("pages.acc.campaigns.errorTitle"),
        t("pages.acc.campaigns.errorText")
      );
    }
  };

  const handleNavigate = () =>
    admin ? handleNavigateAdmin() : handleAdvertiserNavigate();

  const handleCancel = () => {
    setVisible(false);
    setAdType("");
  };

  const closeAdminModal = () => {
    setAdminVisible(false);
    setAdvertiser(null);
  };

  const handleAdvertiser = (item: any) => setAdvertiser(item);

  useEffect(() => {
    const intro = introJs();

    if (
      !showAdminBar &&
      !admin &&
      data?.data.tutorial_status === "NOT_STARTED"
    ) {
      intro.setOptions({
        overlayOpacity: 0.35,
        hidePrev: true,
        prevLabel: t("components.tour.prev"),
        nextLabel: t("components.tour.next"),
        doneLabel: t("components.tour.done"),
        scrollPadding: 20,
        exitOnEsc: false,
        exitOnOverlayClick: false,
        disableInteraction: true,
        positionPrecedence: ["top", "bottom", "left", "right"],
        steps: [
          {
            intro: `<div><h3>${t("components.tour.title")}</h3><p>${t(
              "components.tour.intro"
            )}</p></div>`,
            tooltipClass: cm.intro,
          },
          {
            element: "#menu",
            title: t("components.tour.menu"),
            intro: t("components.tour.menuText"),
            highlightClass: `${cm.menuHighlight} ${cm.highlight}`,
            tooltipClass: cm.menuTooltip,
            scrollTo: "off",
          },
          {
            element: "#campaign",
            title: t("components.tour.campaigns"),
            intro: t("components.tour.campaignsText"),
            position: "right",
            highlightClass: `${cm.menuItemHighlight} ${cm.highlight}`,
            tooltipClass: cm.tooltip,
          },
          {
            element: "#report",
            title: t("components.tour.reports"),
            intro: `<div><p>${t("components.tour.reportsText")}<br/><br/>${t(
              "components.tour.reportsTextSub"
            )}</p></div>`,
            position: "right",
            highlightClass: `${cm.menuItemHighlight} ${cm.highlight}`,
            tooltipClass: `${cm.reportItemTooltip} ${cm.tooltip}`,
          },
          {
            element: "#invoices",
            title: t("components.tour.invoices"),
            intro: t("components.tour.invoicesText"),
            position: "right",
            highlightClass: `${cm.menuItemHighlight} ${cm.highlight}`,
            tooltipClass: cm.tooltip,
          },
          {
            element: "#academy",
            title: t("components.tour.academy"),
            intro: t("components.tour.academyText"),
            position: "right",
            highlightClass: `${cm.menuItemHighlight} ${cm.highlight}`,
            tooltipClass: cm.tooltip,
          },
          {
            element: "#widgets",
            title: t("components.tour.widgets"),
            intro: t("components.tour.widgetsText"),
            position: "top",
            highlightClass: `${cm.widgetsHighlight} ${cm.highlight}`,
            tooltipClass: `${cm.widgetsTooltip} ${cm.tooltip}`,
          },
          {
            element: "#campaigns-table",
            title: t("components.tour.table"),
            intro: t("components.tour.tableText"),
            highlightClass: `${cm.tableHighlight} ${cm.highlight}`,
            tooltipClass: `${cm.tableTooltip} ${cm.tooltip}`,
            scrollTo: "off",
          },
          {
            element: "#campaigns-add-button",
            title: t("components.tour.button"),
            intro: t("components.tour.buttonText"),
            highlightClass: `${cm.buttonHighlight} ${cm.highlight}`,
            tooltipClass: `${cm.buttonTooltip} ${cm.tooltip}`,
          },
          {
            element: "#modal-title",
            title: t("components.tour.modal"),
            intro: t("components.tour.modalText"),
            position: "right",
            highlightClass: `${cm.modalHighlight} ${cm.highlight}`,
            tooltipClass: `${cm.modalTooltip}`,
          },
          {
            element: "#campaign-type-modal",
            title: t("components.tour.type"),
            intro: t("components.tour.typeText"),
            position: "top",
            highlightClass: `${cm.radioHighlight} ${cm.highlight}`,
            tooltipClass: `${cm.radioTooltip} ${cm.tooltip}`,
          },
        ],
      });
      intro.start();
      intro.onchange(() => {
        if (intro.currentStep()! >= 9) {
          setVisible(true);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        } else setVisible(false);
      });
      intro.onexit(() => {
        changeValue("COMPLETED");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [admin, data?.data.tutorial_status]);

  const handleKey = (key: string) => setActiveKey(key);

  const filteredCampaignTypes = config?.data?.configs?.filter(
    (campaign_type: CampaignType) =>
      campaign_type.value === "ACTIVE" || campaign_type.value === "SOON"
  );

  const renderCampaignRow = (campaign_type: CampaignType) => {
    if (campaign_type.value === "SOON") {
      return (
        <CampaignTypesSoonRow
          t={t}
          campaign_type={campaign_type}
          key={campaign_type.config_schema_name}
        />
      );
    }

    return (
      <CampaignTypesActiveRow
        t={t}
        campaign_type={campaign_type}
        key={campaign_type.config_schema_name}
      />
    );
  };

  return (
    <>
      <Tabs
        onChange={handleKey}
        className={cc([
          "bordered-container",
          cm.container,
          introJs().currentStep() === 0 ? cm.intro : "",
        ])}
        id="campaigns-table"
        activeKey={activeKey}
      >
        <Tabs.TabPane
          tab={t("common.listed")}
          key={isCheckupView ? "CHECKUP_ACTIVE" : "ACTIVE"}
        >
          <RetailTable
            tableConfig={activeTabConfig}
            placeholder={t("pages.acc.campaigns.placeholder")}
            button={{
              title: t("pages.acc.campaigns.button"),
              onClick: handleClick,
              dataTest: "create-campaign-button",
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={t("common.archived")}
          key={isCheckupView ? "CHECKUP_ARCHIVED" : "ARCHIVED"}
        >
          <RetailTable
            tableConfig={archivedTabConfig}
            placeholder={t("pages.acc.campaigns.placeholder")}
            addBtnVisible={false}
          />
        </Tabs.TabPane>
      </Tabs>
      <div className={cm.viewButtons}>
        <div>
          <RetailText
            size="xxxs"
            weight="bold"
            onClick={closeCheckUpView}
            className={!isCheckupView ? cm.activeText : ""}
          >
            {t("pages.acc.campaigns.table.default")}
          </RetailText>
          <RetailText
            size="xxxs"
            weight="bold"
            onClick={openCheckUpView}
            className={isCheckupView ? cm.activeText : ""}
          >
            {t("pages.acc.campaigns.table.checkUp")}
          </RetailText>
        </div>
      </div>
      {adminInGeneral && (
        <CampaignDetailsModal
          type="SELECT_ADVERTISER"
          subtitle={t("pages.admin.advertisers.select_advertiser_subtitle")}
          visible={adminVisible}
          onCancel={closeAdminModal}
          onOk={handleAdvertiserSelect}
        >
          <AdvertiserSelect type="CAMPAIGN" onClick={handleAdvertiser} />
        </CampaignDetailsModal>
      )}

      <Modal
        closable
        closeIcon={<CloseOutlined />}
        centered
        visible={visible}
        onCancel={handleCancel}
        forceRender={true}
        bodyStyle={{ backgroundColor: "#fff" }}
        className={cc(["campaign-modal", cm.modal, cm.advertiserModal])}
        title={
          <>
            <Row id="modal-title">
              <Col>
                <RetailTitle level={4} className={cm.title}>
                  {t("components.modal.selectCampaign.title")}
                </RetailTitle>
                <RetailText size="xxxs" className={cm.sub} weight="medium">
                  {t("components.modal.selectCampaign.text")}
                </RetailText>
              </Col>
            </Row>
            {isLoading ? (
              <Spin spinning={isLoading} className={cm.spinner} />
            ) : error ? (
              <section className={cm.errorWrapper}>
                <div className={cm.errorIconContainer}>
                  <CloseOutlined />
                </div>
                <RetailText size="xs" weight="bold" className={cm.errorTitle}>
                  {t(
                    "components.campaignForm.firstStep.errorStates.generalErr"
                  )}
                </RetailText>
              </section>
            ) : (
              <Radio.Group
                className={cm.radioGroup}
                onChange={({ target }: any) => setAdType(target.value)}
                value={adType}
              >
                {filteredCampaignTypes?.map(renderCampaignRow)}
              </Radio.Group>
            )}
          </>
        }
        footer={[
          <Row className={cm.btnContainer} id="campaign-radio-button">
            <RetailMainButton
              hasBackground={false}
              className={cm.cancelBtn}
              onClick={handleCancel}
            >
              {t("common.cancel")}
            </RetailMainButton>
            <RetailMainButton
              data-cy="continue-ad-type"
              hasBackground
              className={cm.okBtn}
              onClick={handleNavigate}
              disabled={adType === ""}
            >
              {t("common.continue")}
            </RetailMainButton>
          </Row>,
        ]}
      />
    </>
  );
};

export default RetailCampaignTable;

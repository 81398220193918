import { useTranslation } from "react-i18next";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { numFormatter } from "utils/helpers";
import Card from "antd/lib/card";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";

import cc from "classcat";
import cm from "./style.module.scss";

export interface RetailCampaignWidgetCardProps {
  text: any;
  handleOpen?(): void;
  handleClose?(): void;
  selected?: any;
  widget?: any;
  days?: number;
}

const RetailCampaignWidgetCard = ({
  text,
  selected,
  widget,
  handleOpen,
  handleClose,
  days,
}: RetailCampaignWidgetCardProps) => {
  const { t, i18n } = useTranslation();

  const isMetricExist = (key: string) => i18n.exists(`common.table.${key}`);

  const renderMetric = (key: string) =>
    isMetricExist(key)
      ? t(`common.table.${key}`)
      : t(`marketplaceDependentTable.${key}`);

  const title = renderMetric(widget[0]);

  const isSelected = selected.length > 0 && title === selected[0].id;

  const mode = widget[1].change_percentage > 0 ? "increment" : "decrement";

  const Icon = () => {
    if (mode === "increment") return <CaretUpOutlined className={cm.up} />;
    else return <CaretDownOutlined className={cm.down} />;
  };

  const ActionText = () => {
    if (isSelected) return <>{t("components.campaignCard.hide")}</>;
    else return <>{t("components.campaignCard.graph")}</>;
  };
  return (
    <Card
      className={cc([cm.cardContainer, isSelected ? cm.selected : ""])}
      actions={[
        <RetailText
          className={cm.btn}
          size="xxxs"
          onClick={isSelected ? handleClose : handleOpen}
        >
          <ActionText />
        </RetailText>,
      ]}
    >
      <Card.Meta
        title={
          <div className={cm.meta}>
            <RetailText className={cm.title}>{title}</RetailText>
            <div className={cc(["flex", cm.percentContainer])}>
              <Icon />
              <RetailText
                className={cm.percentage}
                size="xxxs"
                weight="bold"
              >{`%${numFormatter(widget[1].change_percentage)}`}</RetailText>
            </div>
          </div>
        }
      />
      <RetailTitle className={cm.secondTitle}>{text}</RetailTitle>

      <RetailText size="xxxs" weight="medium" className={cm.message}>
        {t("components.campaignCard.message", { value: days })}
      </RetailText>
    </Card>
  );
};

export default RetailCampaignWidgetCard;

const ru = {
  marketplaceDependent: {
    form: {
      targeting_link:
        "/academy/blog/targeting/hedefleme-nasil-yapilir-hedefleme-turleri-nelerdir",
      bid_link:
        "/academy/blog/management/teklif-nasil-belirlenir-teklif-turleri-nelerdir",
      frequency_link:
        "/academy/blog/measuring/gonderim-turu-nedir-zamanlanmis-rapor-nasil-olusturulur",
      report_type_link: "/academy/blog/measuring/ozel-rapor-nasil-olusturulur",
      product_link:
        "/academy/blog/optimization/urun-secimi-yaparken-nelere-dikkat-edilmeli",
    },
    campaign_form_targeting_text:
      "Вы можете установить стратегию таргетинга для вашей кампании.",
    campaign_form_budget_text:
      "Выберите тип бюджета кампании и установите бюджет. Когда ваш расход достигнет бюджета, кампания автоматически остановится.",
    campaign_form_creative_text:
      "Вы можете загрузить креативы, которые хотите использовать в своей кампании.",
    invoice_modal_help_text:
      "Если вы продолжите, нажав кнопку «Выбрать способ оплаты», вы подтверждаете, что собранная сумма не подлежит возврату.",
    invoice_general_error_modal_title: "Не удалось загрузить баланс!",
    invoice_general_error_modal_text: "Произошла ошибка.",
    invoice_unknown_error_modal_text: "Произошла непредвиденная ошибка.",
    invoice_product_id_error_modal_text:
      "Поле ID товара является обязательным.",
    invoice_signature_error_modal_text: "Проверка подписи не удалась.",
    invoice_transaction_error_modal_text: "ID транзакции уже был использован.",
    invoice_store_error_modal_text:
      "Магазин не принадлежит указанной компании.",
    invoice_balance_error_modal_text: "Недостаточный баланс в магазине.",
    invoice_error_modal_title: "Не удалось загрузить баланс!",
    invoice_error_modal_text: "Произошла ошибка.",
  },
  marketplaceDependentTable: {
    CPC: "Цена за клик (CPC)",
    CPM: "Цена за тысячу показов (CPM)",
    RoAS: "Oкупаемость инвестиций в рекламу (RoAS)",
  },
  marketplaceDependentSettings: {
    api_link: "https://docs.gowit.com/reference/getting-started-1",
    auto_impression_link: "https://docs.gowit.com/reference/sendevent",
    auction_link:
      "https://docs.gowit.com/docs/what-type-of-auctions-are-offered",
    product_link: "https://docs.gowit.com/docs/filters#what-is-a-product-rate",
    stock_link: "https://docs.gowit.com/docs/filters#what-is-a-product-stock",
    targeting_link:
      "https://docs.gowit.com/docs/filters#what-is-a-demographic-targeting-feature",
    bidding_link: "https://docs.gowit.com/docs/bidding-types",
  },
  sider: {
    user: {
      campaign: "Кампании",
      report: "Отчеты",
      campaigns: "Кампании",
      reports: "Отчеты",
      invoices: "Инвойсы",
      academy: "Академия",
      chat: "Поддержка по электронной почте",
    },
    admin: {
      campaign: "Кампании",
      advertisers: "Рекламодатели",
      agencies: "Агентства",
      invoices: "Инвойсы",
      finance: "Финансовый",
      transactions: "Транзакции",
      coupons: "Купоны",
      access: "Доступ",
      users: "Пользователи",
      invited: "Приглашенные",
      synchronization: "История синхронизации",
      activity: "История активности",
      report: "Отчеты",
      settings: "Настройки",
      general: "Общая информация",
      integration: "Информация об интеграции",
      advertiser: "Настройки рекламодателя",
      auction: "Настройки аукциона",
      single: "Настройки кампании",
      campaigns: "Кампании",
      reports: "Отчеты",
      admin_report: "Отчеты",
      ad: "Реклама",
      placement: "Размещение рекламы",
      creatives: "Креативы",
      review: "Проверка рекламы",
      restrictions: "Настройки ограничений",
      console: "Источники данных",
      dev: "Dev. Tools",
      auctions: "Аукционы",
    },
  },
  components: {
    adminBar: {
      rn: "Вы сейчас просматриваете",
      visible: "экран рекламодателя.",
      back: "Вернуться к просмотру администратора",
    },
    advertiserSelect: {
      placeholder: "Выбрать рекламодателя",
    },
    articles: {
      date: "Когда начинается и заканчивается ваша кампания?",
      budget: "Что такое бюджет? Что учитывать при выборе бюджета?",
      bid: "Что такое ставки и какие есть виды ставок?",
      targeting: "Типы таргетинга и как выбрать правильную аудиторию?",
      frequency: "Что такое тип отправки в отчетах?",
      type: "Как создать пользовательский отчет?",
      product: "Что следует учитывать при выборе товара?",
      store: "Что следует учитывать при выборе магазина?",
    },
    breadcrumb: {
      finance: "Транзакции",
      settings: "Общая информация",
      access: "Пользователи",
    },
    bulkUpdate: {
      list: "Перечислить выбранное",
      archive: "Архивировать выбранные",
      pause: "Приостановить выбранные",
      activate: "Активировать выбранные",
      selected: "Выбрано {{value}} единиц",
      bid: "Редактировать выбранные ставки",
      bidLabel: "Ставка",
      bidHelp: "Ставка для выбранных строк будет обновлена.",
      row: "Строка: {{value}}",
      count: "Выбрано:",
      updateAllBids: "Редактировать все ставки",
      updateAllBidsText:
        "Вы можете отредактировать ставки для всех строк в таблице.",
      updatingAllBids: "Вы редактируете ставки для всех строк.",
      updateAllBidsHelp: "Ставки для всех строк в таблице будут обновлены.",
    },
    download: {
      campaigns: "Кампании",
      advertisers: "Рекламодатели",
      agencies: "Агентства",
      transactions: "Транзакции",
      coupons: "Купоны",
      users: "Пользователи",
      sync_logs: "История синхронизации",
      reports: "Отчеты",
      query: "Отчет о производительности",
      PRODUCTS: "Товары",
      KEYWORDS: "Ключевые слова",
      NEGATIVE_KEYWORDS: "Минус-слова",
      AGE_RANGES: "Возраст",
      CITIES: "Город",
      PLATFORMS: "Платформа",
      GENDERS: "Пол",
      PLACEMENTS: "Размещение рекламы",
      CUSTOMERS: "Клиенты",
      CATEGORIES: "Категории",
      NEGATIVE_CATEGORIES: "Минус-категории",
      CREATIVES: "Креативы",
    },
    inbox: {
      title: "Входящие",
      empty: "У вас нет уведомлений.",
      unread: "Только непрочитанные",
      default: "Все",
      archived: "Архивированные",
      readAll: "Отметить все как прочитанные",
      archiveAll: "Архивировать все",
      archiveRead: "Архивировать прочитанные",
      preferences: "Настройки уведомлений",
      global: "Глобальные настройки",
      balance: "Низкий баланс",
      categories: "Низкая ставка (Категории)",
      keywords: "Низкая ставка (Ключевые слова)",
      products: "Остановленные продукты",
      creative: "Отклоненный креатив",
      review: "Креатив создан",
    },
    info: {
      firstTipCreate:
        "Вы можете легко создать успешные кампании, выбрав рекомендованные настройки. Рекомендованные настройки оптимизируют эффективность вашей рекламы.",
      secondTipCreate:
        "Чтобы повысить эффективность ваших кампаний, вы можете добавлять в них товары с достаточным наличием, высокими рейтингами и победителями в BuyBox.",
      thirdTipCreate:
        "Увеличение рекламного бюджета помогает увеличить количество кликов и продаж, делая ваши товары более заметными для ваших клиентов.",
      forthTipCreate:
        "Поставив высокие ставки на свои кампании, вы можете гарантировать, что ваши товары будут отображаться на лучших позициях.",
    },
    invite: {
      selected: "Выбрано {{value}} единиц",
      invite: "Пригласить выбранных",
      reinvite: "Повторно пригласить выбранные",
    },
    modal: {
      campaign: {
        create: "Создать новую кампанию",
        success: "Кампания успешно создана",
        successText:
          'Вы можете перейти на страницу деталей кампании, нажав кнопку "ОК".',
      },
      campaignDetails: {
        products: "Выбор товаров",
        keywords: "Добавить ключевые слова",
        update: "Обновить кампанию",
        negative_keywords: "Добавить минус-слова",
        products_btn: "Добавить товары",
        keywords_btn: "Добавить ключевые слова",
        negative_keywords_btn: "Добавить минус-слова",
        update_btn: "Обновить",
        create_agency: "Добавить новое агентство",
        update_agency: "Обновить агентство",
        create_agency_btn: "Добавить",
        update_agency_btn: "Обновить",
        create_user: "Добавить нового пользователя",
        create_user_marketplace: "Добавить администратора",
        create_user_marketplace_btn: "Добавить",
        create_user_advertiser: "Добавить пользователя-рекламодателя",
        create_user_advertiser_btn: "Добавить",
        select_advertiser: "Выбрать рекламодателя",
        select_advertiser_btn: "Продолжить",
        update_advertiser: "Обновить рекламодателя",
        update_advertiser_btn: "Обновить",
        invoice: "Добавить баланс",
        invoice_btn: "Выбрать способ оплаты",
        coupons: "Определить купон",
        coupons_btn: "Определить купон",
        transactions: "Добавить баланс",
        transactions_btn: "Добавить баланс",
        categories: "Добавить новую категорию",
        categories_btn: "Добавить категории",
        negative_categories: "Добавить минус-категорию",
        negative_categories_btn: "Добавить категории",
        transaction_details: "Детали транзакции",
        settings: "Сохранить изменения",
        settings_btn: "Сохранить",
        creatives: "Добавить креатив",
        creatives_btn: "Добавить креатив",
        approve_balance: "Добавить баланс",
        approve_balance_btn: "Подтвердить транзакцию",
        approve_coupon: "Добавить купон",
        approve_coupon_btn: "Подтвердить купон",
        payment_options: "Добавить баланс",
        payment_options_btn: "Загрузить",
        add_user: "Пригласить нового пользователя",
        add_user_btn: "Отправить приглашение",
        targeting_settings: "Выбор рекламодателя",
        targeting_settings_btn: "Выбрать",
        update_transaction: "Редактировать номер инвойса",
        update_transaction_btn: "Сохранить",
        select_user: "Выбрать тип пользователя",
        select_user_btn: "Продолжить",
        select_language: "Изменить язык",
        select_language_btn: "Изменить",
        campaign_form: "Сводка кампании",
        campaign_form_btn: "Создать кампанию",
        update_bid: "Редактировать ставку",
        update_bid_btn: "Редактировать",
        show_vast_tag: "Получить VAST-тег",
        show_vast_tag_btn: "ОК",
        update_dayparting: "Дневное распределение",
        update_dayparting_btn: "Обновить",
        reject_creative: "Отклонить креатив",
        reject_creative_btn: "Подтвердить",
        refund: "Возврат",
        refund_btn: "Запросить возврат",
        confirm_refund: "Подтвердить возврат",
        confirm_refund_btn: "Подтвердить возврат",
        archive_confirmation: "Подтверждение архивирования",
        archive_confirmation_btn: "Подтвердить и архивировать", 
      },
      selectCampaign: {
        title: "Выберите тип кампании",
        text: "Пожалуйста, выберите тип кампании.",
        soon: "Скоро",
        sponsored_product_availability_title: "Спонсируемый Товар",
        sponsored_product_availability_text:
          "Продвигайте товары покупателям, которые ищут соответствующие ключевые слова или просматривают аналогичные товары.",
        sponsored_brand_availability_title: "Спонсируемый Бренд",
        sponsored_brand_availability_text:
          "Помогите покупателям узнать о вашем бренде и товарах с помощью ярких и привлекательных креативов.",
        sponsored_display_availability_title: "Спонсируемый Дисплей",
        sponsored_display_availability_text:
          "Развивайте свой бизнес, достигая соответствующих аудиторий на сайте и за его пределами.",
        sponsored_store_availability_title: "Спонсируемый Магазин",
        sponsored_store_availability_text:
          "Увеличьте охват вашего магазина и помогите покупателям найти ваши магазины. Обеспечьте целенаправленный и персонализированный опыт покупок.",
        sponsored_video_availability_title: "Спонсируемое Видео",
        sponsored_video_availability_text:
          "Достигайте целевой аудитории и увеличивайте продажи с помощью увлекательных видеорекламных элементов.",
        sponsored_display_with_product_availability_title:
          "Спонсируемый Брендовый Дисплей",
        sponsored_display_with_product_availability_text:
          "Эффективно рекламируйте свои товары, ориентируясь на покупателей с соответствующими интересами как на сайте, так и за его пределами.",
        sponsored_video_with_product_availability_title:
          "Спонсируемый Брендовый Видеоролик",
        sponsored_video_with_product_availability_text:
          "Повышайте узнаваемость продуктов и привлекайте аудиторию с помощью динамичного и увлекательного видеоконтента.",
      },
      success: {
        advertisers_success_title: "Приглашение отправлено",
        advertisers_success_text:
          "Приглашение было отправлено на введенный вами адрес электронной почты. Пользователи должны создать учетную запись, нажав на ссылку в приглашении, чтобы получить доступ к своим аккаунтам.",
        reports_success_title: "Отчет успешно создан",
        reports_success_text:
          "Вы можете продолжить на страницу детализации отчета, нажав кнопку «ОК».",
        invited_success_title: "Пользователь приглашен",
        invited_success_text:
          "Чтобы пользователь появился в списке, он должен щелкнуть на ссылку в приглашении и создать пароль.",
        agencies_success_title: "Агентство успешно создано",
        agencies_success_text: "nan",
        multiple_advertisers_success_title: "Приглашение отправлено",
        multiple_advertisers_success_text:
          "Выбранные рекламодатели были приглашены.",
        invoice_success_title: "Баланс успешно загружен!",
        invoice_success_text:
          "Ваш баланс был загружен. Вы можете создавать новые кампании.",
        create_advertiser_success_title: "Рекламодатель успешно добавлен!",
        create_advertiser_success_text:
          "Вам нужно пригласить рекламодателя для его активации.",
        payment_success_title: "Ваш запрос на загрузку баланса принят!",
        payment_success_text:
          "После подтверждения ваших переводов или платежей через электронный платеж. средства будут зачислены на ваш счет. Процесс подтверждения может занять 2–3 рабочих дня.",
        craftgate_success_title: "Ваш запрос на загрузку баланса получен!",
        craftgate_success_text: "Ваш баланс будет вскоре зачислен на ваш счет.",
        onboarding_success_title: "Поздравляем!",
        onboarding_success_text:
          "Вы успешно завершили процесс регистрации и теперь имеете полный доступ ко всем функциям панели управления! Исследуйте новый интерфейс, создавайте эффективные кампании и управляйте рекламой легко. Нажмите «ОК», чтобы начать!",
      },
      error: {
        craftgate_title: "Баланс не удалось загрузить!",
        craftgate_text: "Не удалось произвести платеж через кредитную карту.",
      },
      warning: {
        campaign_warning_title: "Вы уверены, что хотите покинуть эту страницу?",
        campaign_warning_text: "Ваши изменения не будут сохранены!",
      },
    },
    navbar: {
      search: "Введите для поиска...",
      help: "Помощь и поддержка",
      site: "Вернуться к панели продавца",
    },
    navbarPopover: {
      account: "Изменить аккаунт",
      language: "Изменить язык",
      logout: "Выйти",
    },
    notification: {
      formTitle: "Форма не заполнена!",
      formText: "Пожалуйста, заполните пустые поля и обязательные строки!",
      paused: "Остановка {{value}} прошла успешно.",
      active: "Активация {{value}} прошла успешно.",
      campaigns: "кампанию",
      reports: "отчет",
      products: "товар",
      keywords: "ключевое слово",
      negative_keywords: "минус-слово",
      categories: "категория",
      negative_categories: "минус-категория",
      placements: "рекламное размещение",
      users: "Пользователь",
      advertisers: "Рекламодатель",
      agencies: "агентство",
      ad_placements: "рекламное размещение",
      creatives: "креатив",
      account_users: "Пользователь",
      statusError: "Произошла ошибка.",
      activeReport: "Архивирование отчета прошло успешно.",
      archivedReport: "Восстановление отчета из архива прошло успешно.",
      activeCampaign: "Архивирование кампании прошло успешно.",
      pausedCampaign: "Архивирование кампании прошло успешно.",
      archivedCampaign: "Восстановление кампании из архива прошло успешно.",
      activeAgency: "Архивирование агентства прошло успешно.",
      archivedAgency: "Восстановление агентства из архива прошло успешно.",
      invitedAdvertiser: "Архивирование рекламодателя прошло успешно.",
      activeAdvertiser:
        "Восстановление рекламодателя из архива прошло успешно.",
      archivedAdvertiser: "Архивирование рекламодателя прошло успешно.",
      archived_ad_placement: "Архивирование размещения объявления прошло успешно.",
      archived_reports_bulk: "Архивирование отчета прошло успешно.",
      archived_campaigns_bulk: "Архивирование кампании прошло успешно.",
      archived_users_bulk: "Архивирование пользователя прошло успешно.",
      archived_advertisers_bulk: "Архивирование рекламодателя прошло успешно.",
      archived_agencies_bulk: "Архивирование агентства прошло успешно.",
      archived_account_users_bulk: "Архивирование пользователя прошло успешно.",
      keyword: "Пожалуйста, добавьте хотя бы 1 ключевое слово.",
      category: "Пожалуйста, добавьте хотя бы 1 категорию.",
      invite: "{{value}} приглашение(-й) отправлено успешно.",
      full_invite: "Все приглашения отправлены успешно.",
      rejected: "Отклонение креатива завершено успешно.",
      accepted: "Проверка креатива завершена успешно.",
      uninvitedAdvertiser: "Активация рекламодателя прошла успешно.",
      settings_success: "Настройки успешно сохранены.",
      previous_invite_sent_too_recently:
        "{{value}} приглашение(-й) отправлено слишком недавно. Подождите, чтобы попробовать снова.",
      user_email_is_already_registered:
        "{{value}} адрес(-а) электронной почты уже зарегистрирован(-ы).",
      copiedCampaign: "Кампания успешно скопирована.",
      copyError: "Произошла ошибка при копировании кампании.",
      editableFieldError:
        "Пожалуйста, введите значение больше минимального, иначе оно будет скорректировано соответствующим образом.",
      active_account_user: "Архивирование пользователя прошло успешно.",
      archived_account_user:
        "Восстановление пользователя из архива прошло успешно.",
      bid: "Ставки для {{value}} строк были обновлены.",
      newBid: 'Новая "ставка": {{value}}.',
      creativeUpload: "Креатив успешно добавлен!",
      advertiserBalanceLowSubject: "Ваш баланс на исходе!",
      advertiserBalanceLowBody:
        "Баланс вашего рекламного аккаунта достиг критического уровня. Пополните его сейчас, чтобы обеспечить бесперебойную работу вашей рекламы.",
      campaignKeywordsBelowMinBidSubject:
        "Ставки по ключевым словам слишком низкие в кампании #{{value}}",
      campaignKeywordsBelowMinBidBody:
        "Все ключевые слова в вашей кампании (ID: {{value}}) имеют ставки ниже минимального порога маркетплейса. Обновите ставки, чтобы улучшить видимость.",
      campaignCategoriesBelowMinBidSubject:
        "Ставки по категориям слишком низкие в кампании #{{value}}",
      campaignCategoriesBelowMinBidBody:
        "Все категории в вашей кампании (ID: {{value}}) имеют ставки ниже минимально допустимого уровня. Обновите ставки, чтобы повысить видимость.",
      campaignProductsStoppedSubject:
        "Продукты в кампании #{{value}} неактивны",
      campaignProductsStoppedBody:
        "Продукты в вашей кампании (ID: {{value}}) приостановлены. Рассмотрите возможность добавления новых продуктов, чтобы поддерживать активность кампании.",
      creativeRejectedSubject: "Креатив отклонен в кампании #{{value}}",
      creativeRejectedBody:
        "Креатив в вашей кампании (ID: {{value}}) был отклонен. Пожалуйста, ознакомьтесь с отзывом и обновите креатив в соответствии с требованиями платформы.",
      marketplaceAdvertiserBalanceLowSubject:
        "Баланс рекламодателя {{value}} низкий",
      marketplaceAdvertiserBalanceLowBody:
        "Баланс рекламодателя {{value}} достиг критического уровня. Пополните его сейчас, чтобы их объявления продолжали работать без перебоев.",
      creativeCreatedSubject: "Новый креатив добавлен пользователем {{value}}",
      creativeCreatedBody:
        "{{value}} только что создал новый креатив. Вы можете просмотреть и утвердить его в панели управления маркетплейса.",
      balanceAction: "Пополнить баланс",
      productAction: "Добавить новые продукты",
      bidAction: "Обновить ставки",
      creativeAction: "Обновить креатив",
      reviewAction: "Проверить",
      segments: "сегмент",
    },
    suggestedTag: {
      text: "РЕКОМЕНДУЕМОЕ",
    },
    campaignCard: {
      graph: "Показать в графике",
      hide: "Скрыть",
      message: "Последние 30 дней",
    },
    campaignForm: {
      firstStep: {
        keywordStepTitle: "Информация о ключевых словах",
        categoryStepTitle: "Информация о категориях",
        targetingStepTitle: "Информация о целевой аудитории",
        campaignInfoTitle: "Информация о кампании",
        campaignInfoText:
          "Введите имя, которое будет отображаться в таблице кампаний. Это имя не показывается клиентам. Мы рекомендуем выбрать имя, связанное с товаром, которые вы хотите рекламировать.",
        campaignLabel: "Имя кампании",
        campaignSub: "Введите имя вашей кампании.",
        dateInfoTitle: "Дата кампании",
        dateInfoText: "Установите продолжительность вашей кампании.",
        startDateLabel: "Дата начала",
        startDateSub: "Выберите дату начала кампании.",
        stopped: "Непрерывно",
        stoppedText:
          "Ваша кампания начнется сегодня и будет продолжаться до тех пор, пока вы ее не остановите.",
        stoppedSub: "Дата начала: {{value}}",
        defined: "Дата начала и окончания",
        definedText:
          "Вы можете выбрать интервал дат, в течение которого будет работать ваша кампания.",
        budget: "Бюджет",
        totalBudget: "Общий лимит бюджета",
        totalBudgetLabel: "Общий бюджет",
        dailyBudgetLabel: "Дневной бюджет",
        dailyBudgetExtra:
          "Ежедневные расходы вашей кампании будут максимальной суммой, которую вы укажете.",
        dailyBudgetFilter: "Дневной бюджет",
        dailyBudgetText: "Вы можете установить ваш дневной бюджет.",
        totalBudgetExtra:
          "Ваша кампания будет тратить максимальную сумму, которую вы укажете каждый день.",
        flexibleBudgetLabel: "Дневной бюджет",
        totalBudgetFilter: "Общий бюджет",
        totalBudgetText: "Вы можете установить общий бюджет.",
        bid: "Ставка",
        bidText:
          "Установите тип ставки для вашей кампании. Ваши рекламные расходы рассчитываются за клик на основе выбранного типа ставки.",
        dynamicBid: "Автоматическая ставка",
        dynamicBidText:
          "Ваши расходы за клик будут определяться автоматически.",
        targetAcosTitle: "Целевая рекламная стоимость продаж (ACoS)",
        targetAcosText:
          "Выберите, сколько от вашего дохода от продаж вы хотите потратить на рекламу.",
        default: "По умолчанию",
        advanced: "Расширенные",
        fixedBid: "Фиксированная ставка",
        fixedBidText:
          "Установите фиксированную цену за клик для ключевых слов и категорий. Ваша кампания показывается клиентам на основе ваших предпочтений в таргетинге.",
        productTitle: "Выбор товара",
        productText: "Выберите товары, которые вы хотите рекламировать.",
        acosTooltip:
          "Рекламная стоимость продаж (ACoS) — это процент затрат на рекламу от суммы продаж. Это показатель того, сколько от вашего дохода от продаж вы готовы потратить на рекламу. Например, в кампании с ACoS 10% вы тратите 1 рубль на рекламу за каждые 10 рублей продаж.",
        autoTargeting: "Авто таргетинг",
        autoTargetingText:
          "Для вашей кампании будут автоматически выбраны наиболее подходящие ключевые слова или категории.",
        manualTargeting: "Ручной таргетинг",
        manualTargetingText:
          "Вы можете выбрать ключевые слова или категории, по которым вы хотите показывать вашу кампанию.",
        suggestedBidText: "Рекомендуемая ставка: ",
        acosError:
          "Целевая рекламная стоимость продаж (ACoS) должна быть в пределах 0.0001-100.",
        creativeTitle: "Информация о креативе",
        videoText:
          "Вы можете загрузить креативы, которые хотите использовать в своей кампании.",
        urlTitle: "Перенаправление URL",
        urlText:
          "Это страница, на которую будут перенаправлены пользователи, кликнувшие на вашу рекламу. Вы должны перенаправить пользователей на страницу внутри торговой площадки.",
        displayDraggerTitle: "<strong>Перетащите изображение, или </strong> <0>Выберите файл</0>",
        creativeLabel: "Название креатива",
        size: "Размер:",
        listWarningSize: "5120KB(5MB)",
        listWarningType: "JPG, PNG, GIF",
        recommendedSizes: "Рекомендуемые размеры",
        allSizeText: "Если вы хотите увидеть все размеры на платформе",
        visit: "Посетить",
        allowedFileTypes: "Допустимые типы файлов:",
        maximumFileSize: "Максимальный размер файла:",
        modalTitle: "Размеры креатива",
        modalText:
          "Вы можете загрузить креативы всех указанных размеров. Загрузка креативов рекомендуемых размеров улучшает эффективность вашей рекламы.",
        recommended: "Рекомендуемый размер",
        accepted: "Допустимые размеры",
        creativeError: "Пожалуйста, введите имя креатива.",
        urlError: "Пожалуйста, введите URL.",
        budgetError: "Ваш бюджет должен быть выше {{value}}.",
        budgetErrorMessage: "Пожалуйста, введите ваш бюджет.",
        invalid: "НЕВЕРНЫЙ РАЗМЕР",
        advancedOptions: "Расширенные настройки",
        placements: "Размещение рекламы",
        placementsText:
          "Выберите места, в которых вы хотите, чтобы ваша реклама отображалась.",
        in_search: "Результаты поиска",
        in_search_text:
          "Ваша реклама отображается в соответствующих результатах поиска.",
        in_product_detail_page: "Страницы описания товара",
        in_product_detail_page_text:
          "Ваша реклама отображается на соответствующих страницах описания товара.",
        in_category: "Категории",
        in_category_text:
          "Ваша реклама отображается на соответствующих страницах категорий.",
        in_home_page: "Главная страница",
        in_home_page_text: "Ваша реклама отображается на главной странице.",
        in_collection: "Коллекция",
        in_collection_text:
          "Ваша реклама отображается на соответствующих страницах коллекций.",
        storeTitle: "Выбор магазина",
        storeText: "Выберите магазины, которые вы хотите рекламировать.",
        info: "ОБЩАЯ ИНФОРМАЦИЯ",
        advertiser: "Название рекламодателя:",
        budgetType: "Тип бюджета:",
        bidType: "Тип ставки:",
        standart: "Автоматическая ставка",
        fixed: "Фиксированная ставка",
        max_acos: "Целевая рекламная стоимость продаж (ACoS)",
        fixed_info: "Ставка:",
        max_acos_info: "Целевая рекламная стоимость продаж (ACoS):",
        targeting: "ТАРГЕТИНГ",
        custom: "Пользовательский",
        durationText:
          "Если ваша кампания достигнет цели по дневному бюджету каждый день, она будет активна в течение <strong>{{value}} дней</strong>.",
        dayParting: "Дневное распределение",
        dayPartingText:
          "Определите дни и часы, в которые ваша кампания будет оставаться активной.",
        allTimes: "Круглосуточно",
        allTimesText: "Ваша кампания активна весь день и круглосуточно.",
        scheduled: "Выбрать диапазон дат",
        scheduledText: "Ваша кампания активна в указанные вами дни и часы.",
        dayPartingTableText:
          "Пожалуйста, отметьте соответствующие <strong>боксы</strong> ниже, чтобы выбрать день и интервал времени, который вы хотите. Если вы хотите выбрать все временные интервалы в течение дня, вы можете использовать опцию <strong>'Весь день'</strong>.",
        allDay: "Весь день",
        clear: "Очистить выбор",
        videoSideTitle: "Добавить креатив",
        videoSideText: "Выберите типы креативов и определите их форматы.",
        firstDrawerTitle: "Шаг 1: Выберите тип креатива",
        creativeTypeTitle: "Тип креатива",
        creativeTypeText:
          "Выберите тип креатива, подходящий для вашей рекламы.",
        mp4Title: "Создать через видео файл",
        mp4Text: "Вы можете создать ваш креатив с видеофайлом формата .MP4.",
        vastTitle: "Создать с использованием VAST-тега",
        vastText: "Вы можете создать ваш креатив с помощью данных VAST-тега.",
        secondDrawerTitle: "Шаг 2: Добавить через видео файл",
        creativeNameTitle: "Название креатива",
        creativeNameText: "Введите название для вашего креатива.",
        creativeVideoLabel: "Введите название креатива.",
        video_upload: "Загрузить видео",
        video_upload_drawer: "Загрузите видеофайл для показа в ваших объявлениях.",
        videoDraggerTitle: "<strong>Перетащите видео, или </strong> <0>Выберите файл</0>",
        maxVideoSize: "10240KB(10MB)",
        videoFormat: "MP4",
        changeVideo: "Изменить видео",
        creativeName: "<strong>Название креатива:</strong> {{value}}",
        updateDrawerTitle: "Обновить креатив",
        videoAd: "Спонсируемое Видео",
        vast: "VAST-тег",
        vastDrawer:
          "Добавьте VAST-тег для видео, которое будет отображаться в вашей рекламе.",
        videoInnerTitle: "Мы обрабатываем ваше видео!",
        videoInnerText:
          "Когда система завершит обработку, ваше видео будет готово к просмотру!",
        creativeLanguageTitle: "Язык креатива",
        creativeLanguageText: "Выберите языки для вашего креатива.",
        creativeLanguageSearch: "Поиск языка",
        autoTargetingProductWarning:
          "Для включения автоматического таргетинга необходимо выбрать как минимум <strong>“1”</strong> продукт на этапе <strong>подключенных продуктов</strong>.",
        connectedProductsTitle: "Подключенные продукты",
        connectedProductsText:
          "Продукты, связанные с креативами выше, специально подобранные для вас.",
        showProducts: "Показать подключенные продукты",
        showProductsText:
          "Активируйте эту опцию, чтобы включить продукты, связанные с вашими креативами. Ассоциация соответствующих продуктов с вашими креативами помогает повысить релевантность рекламы.",
        promoteProduct: "Продвигать продукты",
        promoteProductText:
          "Эта функция гарантирует, что добавленные в вашу кампанию продукты будут отображаться в заметном месте.",
        promoteProductWarning:
          "Обратите внимание, что после выбора этой опции её нельзя изменить.",
        closeProducts: "Отключить подключенные продукты",
        closeProductsText:
          "Отключение использования продуктов, связанных с вашими креативами, не рекомендуется, так как это может снизить релевантность вашей рекламы.",
        spendingTitle: "Распределение бюджета",
        spendingText:
          "Определите временное распределение и интенсивность расходования вашего рекламного бюджета.",
        spendingSub: "Определите, как будет распределяться ваш бюджет.",
        asap: "Как можно быстрее",
        front_loaded: "С акцентом в начале",
        evenly: "Равномерно",
        frontPercentage: "Процент",
        multiRegion: "Выбор региона",
        multiRegionText:
          "Вы можете запустить свою кампанию в нескольких или определённых регионах.",
        allRegions: "Все регионы",
        allRegionsText: "Ваша кампания будет опубликована во всех регионах.",
        selectedRegions: "Выбранные регионы",
        selectedRegionsText:
          "Ваша кампания будет опубликована только в выбранных регионах.",
        regionPlaceholder: "Выберите регионы",
        evenlyText:
          "Кампания продвигается стабильно, с равномерным распределением бюджета по всем временным периодам.",
        asapText:
          "Бюджет расходуется быстро, чтобы получить быстрые результаты в начале кампании.",
        front_loadedText:
          "Основная часть бюджета расходуется в начале кампании, а затем постепенно снижается для поддержания присутствия с течением времени.",
        displayUploadTitle: "Креатив через файл изображения",
        displayUploadText:
          "Вы можете создать свой креатив, загрузив файл в формате .JPEG, .PNG или .GIF.",
        display_upload_second_drawer_title:
          "Шаг 2: Добавление через файл изображения",
        display_upload: "Загрузить изображение",
        display_upload_drawer:
          "Загрузите файл изображения, который будет использоваться в ваших объявлениях.",
        changeDisplay: "Изменить изображение",
        displayErrorTitle: "Ошибка изображения!",
        displayErrorText: "Загружаемое изображение имеет некорректные размеры.",
        newDisplay: "Загрузить новое изображение",
        bulk: "Массовая загрузка",
        scriptUploadTitle: "Креатив с использованием скрипта",
        scriptUploadText:
          "Вы можете создать свой креатив, используя код скрипта.",
        script: "Код скрипта",
        script_drawer:
          "Введите код, который будет использоваться в ваших объявлениях.",
        creativeSizeTitle: "Размер объявления",
        creativeSizeText: "Выберите размер объявления для вашего креатива.",
        creativeSizeSearch: "Выберите размер",
        vast_second_drawer_title: "Шаг 2: Добавление через VAST-тег",
        script_second_drawer_title:
          "Шаг 2: Добавление с использованием кода скрипта",
        code: "Редактировать код",
        preview: "Предварительный просмотр",
        errorStates: {
          name: "Пожалуйста, введите название кампании.",
          keywordErr: "Ваше ключевое слово должно быть уникальным.",
          advertiserErr: "Рекламодатель не активен.",
          placementsError:
            "Пожалуйста, выберите хотя бы одно место для рекламы.",
          productLimitErr: "Вы можете добавить до 250 товаров.",
          sameKeyword:
            "Нельзя включать одно и то же ключевое слово как в целевые, так и в отрицательные ключевые слова.",
          duplicateKeyword: "Ключевое слово уже добавлено в кампанию.",
          duplicateNegativeKeyword: "Минус-слово уже добавлено в кампанию.",
          generalErr: "Произошла ошибка.",
          campaignLimitErr:
            "Вы достигли лимита кампаний для торговых площадок SMO.",
          creativeLimitErr:
            "Лимит креативов для торговых площадок SMO составляет 100, и этот лимит достигнут.",
          creativeSizeErr:
            "Размер файла превышает лимит для торговых площадок SMO. Пожалуйста, загрузите файл размером менее 150 КБ.",
          vast: "Пожалуйста, введите действительный VAST-тег.",
          keywordLimitErr:
            "В кампанию можно добавить не более 100 ключевых слов.",
          categoryLimitErr: "В кампанию можно добавить не более 100 категорий.",
          frontPercentage:
            "Процент начальной загрузки должен быть в пределах от 0 до 100.",
          creativeDimensionErr:
            "Пожалуйста, загрузите креатив с правильными размерами.",
          languageErr: "Пожалуйста, добавьте язык.",
          sizeErr: "Пожалуйста, выберите размер.",
          script:
            "Некорректный HTML-креатив. Убедитесь, что он содержит тег <meta> с атрибутом content в формате width={value},height={value}, например, <meta content='width=300,height=250'>.",
        },
      },
      secondStep: {
        wordTargeting: "Таргетинг по ключевым словам",
        wordTargetingText:
          "Определите, по каким ключевым словам будет отображаться ваша реклама.",
        categorySub:
          "Решите, в каких категориях вы хотите отображать вашу рекламу.",
        autoTargeting: "Авто таргетинг",
        autoTargetingText:
          "Ваша реклама будет отображаться в самых релевантных ключевых словах и категориях. Вы можете остановить ключевые слова на странице сведений о кампании.",
        manualTargeting: "Ручной таргетинг",
        manualTargetingText:
          "Вы можете выбрать ключевые слова для вашей рекламы.",
        defaultBid: "Ставка по умолчанию",
        keywordTitle: "Целевые ключевые слова",
        keywordText:
          "Добавьте ключевые слова, по которым вы хотите показывать свою кампанию.",
        negativeTitle: "Минус-слова",
        negativeText:
          "Добавьте ключевые слова, по которым вы не хотите показывать вашу кампанию.",
        placementTitle: "Тип таргетинга",
        placementText:
          "Вы можете выбрать ключевые слова или категории, по которым будет отображаться ваша кампания.",
        search: "Таргетинг по ключевым словам",
        searchText:
          "Добавьте ключевые слова, по которым вы хотите показывать свою кампанию.",
        categories: "Таргетинг по категориям",
        categoriesText:
          "Выберите категории, по которым вы хотите показывать свою кампанию.",
        categoryTargeting: "Таргетинг",
        categoryTargetingTitle:
          "Определите, в каких категориях будет отображаться ваша реклама.",
        categoryTargetingText:
          "Добавьте категории, в которых будет отображаться ваша реклама.",
        category: "Целевые категории",
        negativeCategoryTitle: "Минус-категории",
        negativeCategoryText:
          "Добавьте категории, в которых вы не хотите показывать вашу рекламу.",
        autoCategoryText:
          "Ваша реклама будет отображаться в наиболее релевантных категориях.",
        manualCategoryText: "Вы можете установить категории для вашей рекламы.",
        placement: "Таргетинг",
        targetingText:
          "Вы можете установить стратегию таргетинга для вашей кампании.",
      },
      thirdStep: {
        targeting: "Выбор аудитории",
        targetingText:
          "Определите, в каких аудиториях вы хотите показывать вашу рекламу.",
        autoTargeting: "Автоматический таргетинг",
        autoTargetingText:
          "Ваша реклама будет отображаться в самых релевантных ключевых словах и категориях.",
        manualTargeting: "Ручной таргетинг",
        manualTargetingText:
          "Вы можете определить, в каких аудиториях будет отображаться ваша реклама.",
        platform: "Таргетинг по платформе",
        platformText:
          "Определите, на каких платформах будет отображаться ваша реклама.",
        tablet: "Планшет",
        mobile: "Мобильное устройство",
        app: "Мобильное приложение",
        desktop: "Десктоп",
        age: "Таргетинг по возрасту",
        ageText:
          "Определите, на какую возрастную группу вы хотите показывать вашу рекламу.",
        city: "Таргетинг по городу",
        cityText:
          "Определите, в каких городах вы хотите показывать вашу рекламу.",
        allCities: "Все города",
        selectedCities: "Выбранные города",
        cityPlaceholder: "Выбрать город",
        customer: "Таргетинг по клиентам",
        customerText:
          "Определите, каким клиентам вы хотите показывать вашу рекламу, исходя из их истории покупок.",
        presentCustomers: "Существующие клиенты",
        presentCustomersText:
          "Таргетинг на клиентов, которые уже заказывали товары, которые вы рекламируете.",
        newCustomers: "Новые клиенты",
        newCustomersText:
          "Таргетинг на клиентов, которые никогда не заказывали товары, которые вы рекламируете.",
        gender: "Таргетинг по полу",
        genderText:
          "Определите, какому полу вы хотите показывать вашу рекламу.",
        female: "Женский",
        male: "Мужской",
      },
    },
    columnPopover: {
      title: "Редактировать столбцы",
    },
    empty: {
      product_title: "Пожалуйста, выберите товар.",
      store_title: "Пожалуйста, выберите магазин.",
      dimensions_title: "Вы еще не добавили размер.",
      product_text:
        'Вы можете добавить товары, которые хотите рекламировать, нажав на кнопку "Добавить" в списке товаров слева.',
      store_text: "Вы можете добавить магазины, которые хотите рекламировать.",
      dimensions_text:
        "Вы можете добавить размеры для детализации уровня ваших результатов кампании.",
      null_title: "Нет данных",
      null_text: "В таблице нет данных.",
      categories_title:
        "Пожалуйста, добавьте категории, которые вы будете таргетировать.",
      categories_text:
        'Укажите категории, в которых вы хотите, чтобы ваша реклама отображалась, введя категорию или нажав кнопку "Добавить" в таблице предлагаемых категорий.',
      keywords_title:
        "Пожалуйста, добавьте ключевые слова, которые вы будете таргетировать.",
      keywords_text:
        'Укажите ключевые слова, по которым вы хотите показывать вашу рекламу, введя ключевое слово или нажав на кнопку "Добавить" в таблице предлагаемых ключевых слов.',
      negative_keywords_title: "Вы еще не добавили минус-слова.",
      negative_keywords_text:
        "Вы можете указать минус-слова, по которым вы не хотите показывать вашу рекламу, введя слово.",
      negative_categories_title: "Вы еще не добавили минус-категории.",
      negative_categories_text:
        "Вы можете указать минус-категории, по которым вы не хотите показывать вашу рекламу.",
      campaigns_table_title: "Вы не создали ни одной кампании",
      campaigns_table_text:
        'Вы можете создать вашу первую кампанию, нажав кнопку "Добавить новую кампанию".',
      reports_table_title: "Вы не создали ни одного отчета",
      reports_table_text:
        'Вы можете создать ваш первый отчет, нажав кнопку "Добавить новый отчет".',
      invoices_table_title: "Вы еще не загрузили баланс",
      invoices_table_text:
        "Вы можете добавить баланс рекламы, обратившись к своему аккаунт-менеджеру.",
      advertisers_table_title:
        "С вашей учетной записью не связан ни один рекламодатель",
      advertisers_table_text:
        "Пожалуйста, свяжитесь со своим аккаунт-менеджером.",
      agencies_table_title: "Вы не создали агентство",
      agencies_table_text:
        'Вы можете создать агентство, нажав кнопку "Добавить новое агентство".',
      transactions_table_title: "Вы еще не загрузили баланс",
      transactions_table_text:
        'Вы можете добавить баланс рекламы в список рекламодателей, нажав на кнопку "Загрузить баланс".',
      coupons_table_title: "Вы не создали купоны",
      coupons_table_text:
        'Вы можете определить купоны для ваших рекламодателей, нажав на кнопку "Определить купон".',
      account_users_table_title: "Вы не создали пользователя",
      account_users_table_text:
        'Вы можете создать пользователя, нажав на кнопку "Добавить нового пользователя" во вкладке "Приглашенные".',
      archived_table_reports_title: "Нет архивных отчетов",
      archived_table_campaigns_title: "Нет архивных кампаний",
      archived_table_agencies_title: "Нет архивных агентств",
      archived_table_account_users_title: "Нет архивных пользователей",
      archived_table_advertisers_title: "Нет архивных рекламодателей",
      archived_table_ad_placements_title: "Нет архивированных размещений рекламы",
      invited_table_users_title: "Нет приглашенных пользователей",
      searched_table_title:
        "Записей, соответствующих вашему запросу, не найдено.",
      searched_table_text: "nan",
      searched_title: "Записей, соответствующих вашему запросу, не найдено.",
      searched_text: "nan",
      products_table_title: "Вы еще не добавили ни одного товара.",
      products_table_text:
        'Вы можете добавить товары, которые хотите рекламировать, нажав на кнопку "Добавить товары”.',
      keywords_table_title: "Вы еще не добавили ключевые слова.",
      keywords_table_text:
        "Вы можете указать ключевые слова, по которым вы хотите показывать вашу рекламу, введя ключевое слово.",
      categories_table_title: "Вы еще не добавили категории.",
      categories_table_text:
        "Вы можете указать категории, по которым вы хотите показывать вашу рекламу, введя категорию.",
      negative_keywords_table_title: "Вы еще не добавили минус-слова.",
      negative_keywords_table_text:
        "Вы можете указать минус-слова, по которым вы не хотите показывать вашу рекламу, введя слово.",
      negative_categories_table_title: "Вы еще не добавили минус-категории.",
      negative_categories_table_text:
        "Вы можете указать минус-категории, по которым вы не хотите показывать вашу рекламу.",
      sync_logs_table_title: "Нет данных о синхронизации",
      sync_logs_table_text: "Данных истории синхронизации не найдено.",
      ad_placements_table_title: "Вы еще не добавили размещение рекламы.",
      ad_placements_table_text:
        'Вы можете добавить размещение рекламы, нажав на кнопку "Добавить размещение рекламы".',
      creatives_table_title: "Креативы еще не добавлены!",
      creatives_table_text: "Загруженные креативы появятся здесь.",
      creatives_relations_table_title: "Вы не создали креатив.",
      creatives_relations_table_text:
        "Вы можете создать новый креатив из формы кампании.",
      creativeForm_title: "Вы еще не добавили креативы.",
      creativeForm_text: "Рекомендуемые размеры креативов:",
      invites_table_title: "Вы не пригласили пользователя",
      invites_table_text:
        'Вы можете пригласить пользователей, нажав на кнопку "Добавить нового пользователя".',
      review_title: "В настоящее время нет креативов для проверки.",
      review_text:
        "Все креативы утверждены, или креативы не загружены. Креативы, ожидающие утверждения, появятся на этом экране.",
      accepted_table_creatives_title: "Креативы еще не утверждены!",
      accepted_table_creatives_text:
        "Все креативы отклонены, или креативы не загружены.",
      rejected_table_creatives_title: "Креативы еще не отклонены!",
      rejected_table_creatives_text:
        "Все креативы утверждены, или креативы не загружены.",
      pending_table_creatives_title: "Креативы еще не добавлены!",
      pending_table_creatives_text:
        "Все креативы проверены, или креативы не загружены.",
      blacklist_table_title: "Ограничения по ключевым словам не найдены.",
      blacklist_table_text:
        'Вы можете добавить ограничение, нажав кнопку "Добавить новое ключевое слово".',
      cities_table_title: "Вы еще не добавили города.",
      cities_table_text: "nan",
      age_ranges_table_title: "Вы еще не добавили возрастные диапазоны.",
      age_ranges_table_text: "nan",
      genders_table_title: "Вы еще не добавили полы.",
      genders_table_text: "nan",
      platforms_table_title: "Вы еще не добавили платформы.",
      platforms_table_text: "nan",
      customers_table_title: "Вы еще не добавили клиентов.",
      customers_table_text: "nan",
      report_loading_title: "Идет создание отчета",
      report_loading_text:
        "Ваш отчет в процессе создания. Это может занять некоторое время в зависимости от сложности и объема отчета.",
      data_sources_table_title: "Источник данных не найден",
      data_sources_table_text:
        "В данный момент нет источников данных. Добавьте один, чтобы начать видеть детали импорта и историю.",
      logs_title: "Журналы не найдены",
      logs_text:
        "В настоящее время в этой категории нет записанных журналов. Как только появятся соответствующие события, журналы будут отображаться здесь.",
      segments_table_title: "Вы еще не добавили ни одного сегмента.",
      segments_table_text: "",
    },
    endDatePicker: {
      label: "Дата окончания",
      campaigns: "Выберите дату окончания кампании.",
      reports: "Выберите дату окончания отчета.",
      button: "Нет даты окончания",
    },
    reportForm: {
      title: "Информация об отчете",
      nameTitle: "Имя отчета",
      nameText: "Введите имя, которое будет отображаться в таблице отчетов.",
      nameSub: "Введите имя вашего отчета.",
      reportTypeTitle: "Доставка",
      reportTypeText:
        "Выберите тип доставки вашего отчета. Отчеты, которые вы сгенерировали, будут доставлены в соответствии с вашими предпочтениями.",
      static: "Фиксированный",
      staticText: "Отчет выполняется один раз и отображается в отчетности.",
      scheduled: "Запланированный",
      scheduledText: "Выберите частоту и получателей вашего отчета.",
      frequency: "Выберите частоту вашего отчета.",
      daily: "Ежедневный",
      weekly: "Еженедельный",
      monthly: "Ежемесячный",
      sendDate: "Выберите дату, когда вы хотите получить ваш первый отчет.",
      firstDay: "Первый день месяца",
      lastDay: "Последний день месяца",
      exportType: "Выберите формат, в котором вы хотите получить ваш отчет.",
      mail: "Введите адреса электронной почты, на которые будет отправлен ваш отчет.",
      dateTitle: "Дата отчета",
      dateText: "Выберите начальную и конечную дату вашего отчета.",
      startDateSub: "Выберите дату начала отчета.",
      reportKindTitle: "Тип отчета",
      reportKindText:
        "Вы можете легко оценить эффективность с помощью предустановленных отчетов. С помощью пользовательского отчета вы можете создавать пользовательские отчеты, добавляя размеры, метрики и фильтры.",
      predefined: "Предустановленный отчет",
      predefinedPlaceholder: "Выбрать тип предустановленного отчета",
      special: "Пользовательский отчет",
      breaks: "Размеры",
      breaksText: "Выберите размеры, которые вы хотите включить в отчет.",
      breaksPlaceholder: "Поиск размеров",
      maxWarning: "*Вы можете добавить до 3 размеров в ваш отчет.",
      clear: "ОЧИСТИТЬ",
      metrics: "Метрики",
      metricsText: "Выберите метрики, которые вы хотите включить в ваш отчет.",
      filters: "Фильтры:",
      filtersText:
        "Выберите фильтры, которые вы хотите применить к размерам и метрикам.",
      product: "Эффективность товара",
      product_category: "Эффективность категории",
      product_brand: "Эффективность бренда",
      keyword: "Эффективность ключевого слова",
      campaign: "Эффективность кампании",
      advertiser: "Эффективность рекламодателя",
      agency: "Эффективность агентства",
      errorStates: {
        date: "Пожалуйста, выберите дату.",
        mail: "Пожалуйста, введите адрес электронной почты.",
        type: "Пожалуйста, выберите тип отчета.",
        metric: "Пожалуйста, выберите хотя бы одну метрику.",
      },
      dimensions: {
        campaignId: "ID кампании",
        campaignName: "Название кампании",
        campaignBidType: "Тип ставки",
        campaignBudgetType: "Тип бюджета",
        product: "Товар",
        categoryName: "Страницы категорий",
        keywordText: "Ключевое слово",
        productBrand: "Бренд товара",
        productName: "Товар",
        day: "День",
        week: "Неделя",
        month: "Месяц",
        year: "Год",
        hour: "Час",
        advertiser: "ID рекламодателя",
        advertiserName: "Рекламодатель",
        agency: "ID агентства",
        agencyName: "Агентство",
        customerDeviceType: "Тип устройства клиента",
        saleProductName: "Косвенная продажа товара",
        placementId: "ID размещения",
        customerType: "Тип клиента",
        productRetailerTaxonomy: "Категория товара",
        adPlacementCustomKey: "ID размещения рекламы",
        adPlacementName: "Название размещения рекламы",
        placementText: "Текст размещения",
        in_search: "Результаты поиска",
        in_category: "Страницы категорий",
        in_product_detail_page: "Страницы описания товара",
        in_home_page: "Главная страница",
        campaignAdType: "Тип кампании",
        campaignBudget: "Бюджет",
        campaignFixedBid: "Фиксированная ставка",
        keywordBid: "Ставка ключевого слова",
        categoryBid: "Ставка категории",
        advertiserResourceID: "ID продавца",
        productSKU: "ID товара",
        customerDeviceEnvironmentType: "Тип среды устройства клиента",
        campaignDailyBudget: "Дневной бюджет",
        campaignTotalBudget: "Общий бюджет",
      },
      metric: {
        sumImpression: "Показы",
        sumViewableImpression: "Просмотры",
        sumClick: "Клики",
        CTR: "Кликабельность (CTR)",
        CVR: "Показатель конверсии (CVR)",
        sumSale: "Продажи",
        ACoS: "Целевая рекламная стоимость продаж (ACoS)",
        sumDirectSale: "Прямые продажи",
        sumIndirectSale: "Косвенные продажи",
        sumDirectSaleAmount: "Сумма прямых продаж",
        sumIndirectSaleAmount: "Сумма косвенных продаж",
        sumSaleAmount: "Сумма продаж",
        sumSpend: "Расходы",
        sale: "Продажи",
        sumVideoStart: "Запуски видео",
        sumVideoComplete: "Завершения видео",
        VCR: "VCR",
        viewabilityRate: "Коэффициент видимости",
        deliveryRate: "Коэффициент доставки",
        adResponseYieldRate: "Коэффициент отклика рекламы",
        fillRate: "Коэффициент заполнения",
        sumPostClickSale: "Продажа после клика",
        sumPostViewSale: "Продажа после просмотра",
        sumPostClickSaleAmount: "Сумма продаж после клика",
        sumPostViewSaleAmount: "Сумма продаж после просмотра",
        sumPostClickDirectSale: "Прямая продажа после клика",
        sumPostClickIndirectSale: "Косвенная продажа после клика",
        sumPostViewDirectSale: "Прямая продажа после просмотра",
        sumPostViewIndirectSale: "Косвенная продажа после просмотра",
        sumPostClickDirectSaleAmount: "Сумма прямых продаж после клика",
        sumPostClickIndirectSaleAmount: "Сумма косвенных продаж после клика",
        sumPostViewDirectSaleAmount: "Сумма прямых продаж после просмотра",
        sumPostViewIndirectSaleAmount: "Сумма косвенных продаж после просмотра",
        ACoSByClick: "ACoS по клику",
        ACoSByView: "ACoS по просмотру",
        RoASByClick: "RoAS по клику",
        RoASByView: "RoAS по просмотру",
        CVRByClick: "CVR по клику",
        CVRByView: "CVR по просмотру",
      },
    },
    selectFilter: {
      title: "Параметры фильтра",
      placeholder: "Выбрать фильтр",
      budgetOpt: "Оставшийся бюджет",
      budgetAdvertiser: "Название рекламодателя",
      placeholderCondition: "Выбрать условие",
      bigger: "больше",
      smaller: "меньше",
      equal: "равно",
      notEqual: "не равно",
      any: "содержит",
      add: "Добавить фильтр",
      placeholderValue: "Выберите значение",
      enter: "Введите значение",
      date: "Выбрать дату",
      reportsText:
        "Выберите фильтр, который вы хотите применить к вашему отчету.",
    },
    searchBar: {
      keywords: "Добавить ключевые слова...",
      negative_keywords: "Добавить ключевые слова...",
      categories: "Добавить категории...",
      negative_categories: "Добавить категории...",
    },
    settingsHeader: {
      general: "Общая информация",
      generalText: "Общая информация о торговой площадке",
      integration: "Информация об интеграции",
      integrationText:
        "Информация об интеграции, необходимая для передачи данных",
      advertiser: "Настройки рекламодателя",
      advertiserText: "Настройки, связанные с рекламодателями.",
      auction: "Настройки аукциона",
      auctionText: "Настройки, связанные с аукционом",
      campaign: "Настройки кампании",
      campaignText: "Настройки, связанные с созданием кампании",
      bid: "Ограничения ставок",
      bidText: "Настройки для правил ставок на уровне платформы",
      conversion: "Конверсия",
      conversionText:
        "Настройки, связанные с вашими целями конверсии на платформе",
      category: "Ограничения по категориям",
      categoryText: "Ограничения на категории, отображаемые на вашей платформе",
      keyword: "Ограничения по ключевым словам",
      keywordText:
        "Ограничения по ключевым словам, которые ищутся на вашей платформе",
    },
    stepsNavbar: {
      report_create: "Создать отчет",
      campaign_create: "Создать кампанию",
      report_edit: "Обновить отчет",
      step: "Следующий шаг {{value}}",
      advertiser: "Рекламодатель:",
    },
    suggestionCard: {
      addAll: "Добавить все",
    },
    table: {
      keywords: {
        keyword: "Ключевое слово",
        matchType: "Тип соответствия",
        suggested: "Рекомендуемая ставка",
        dynamic: "Авто",
        volume: "Объем поиска",
        competition: "Конкуренция",
        search: "Добавить ключевые слова...",
        suggestTitle: "Рекомендуемые ключевые слова",
        suggestText: "Рекомендуемые ключевые слова для ваших товаров.",
        negativePlaceholder: "Добавить минус-слова...",
        exact:
          "Поисковые запросы, точно соответствующие вашему ключевому слову.",
        phrase:
          "Поисковые запросы, содержащие элементы вашего ключевого слова в том же порядке.",
        broad:
          "Поисковые запросы, содержащие элементы вашего ключевого слова в любом порядке.",
        warning: "Минимальное значение должно быть {{value}}.",
      },
      products: {
        productSearch:
          "Искать товар, единица складского учёта (SKU) или штрих-код.",
        storeSearch: "Искать магазин",
        loading: "Загрузка",
        title: "Пожалуйста, выберите товар.",
        text: 'Вы можете добавить товары, которые хотите рекламировать, нажав на кнопку "Добавить" в списке товаров слева.',
        productSelected: "Выбранные товары",
        storeSelected: "Выбранные магазины",
        remove: "Удалить все",
        price: "Цена",
        stock: "Запас",
        rating: "Рейтинг",
        drawerTitle: "Множественный выбор товаров",
        sku: "Единица складского учёта (SKU) или список штрих-кодов",
        skuText:
          "Добавьте единицу складского учёта (SKU) или штрих-код товара, который вы хотите добавить, по одному на строку, в поле ниже.",
        categories: "Все категории",
        bulk: "Множественный выбор",
        all: "Показать все",
        selected: "Показать выбранные товары",
        total: "Всего <strong>{{value}} товаров</strong>.",
        variable: "Переменная",
      },
      categories: {
        suggestTitle: "Рекомендуемые категории",
        suggestText: "Рекомендуемые категории для ваших товаров",
      },
      result: {
        campaignId: "ID кампании",
        campaignName: "Название кампании",
        campaign: "ID кампании",
        campaignBidType: "Тип ставки",
        campaignBudgetType: "Тип бюджета",
        advertiserName: "Название рекламодателя",
        agencyName: "Название агентства",
        product: "Товар",
        productName: "Товар",
        categoryText: "Категория",
        keyword: "ID ключевого слова",
        keywordText: "Текст ключевого слова",
        keywordMatchType: "Тип соответствия",
        productBrand: "Бренд товара",
        day: "День",
        week: "Неделя",
        month: "Месяц",
        year: "Год",
        hour: "Час",
        sumImpression: "Показы",
        sumViewableImpression: "Просмотры",
        sumClick: "Клики",
        CTR: "Кликабельность (CTR)",
        CVR: "Показатель конверсии (CVR)",
        sumSale: "Продажи",
        ACoS: "Целевая рекламная стоимость продаж (ACoS)",
        sumDirectSale: "Прямые продажи",
        sumIndirectSale: "Косвенные продажи",
        sumDirectSaleAmount: "Сумма прямых продаж",
        sumIndirectSaleAmount: "Сумма косвенных продаж",
        sumSaleAmount: "Сумма продаж",
        sumSpend: "Расходы",
        campaignStartDate: "Дата начала",
        campaignEndDate: "Дата окончания",
        campaignStatus: "Статус",
        campaignBudget: "Бюджет",
        advertiser: "Рекламодатель",
        agency: "Агентство",
        weekOf: "Неделя {{value}}",
        customerDeviceType: "Тип устройства клиента",
        saleProductName: "Косвенная продажа товара",
        placementId: "ID размещения",
        customerType: "Тип клиента",
        categoryName: "Страницы категорий",
        productRetailerTaxonomy: "Категория товара",
        adPlacementCustomKey: "ID размещения рекламы",
        adPlacementName: "Название размещения рекламы",
        placementText: "Текст размещения",
        in_search: "Результаты поиска",
        in_category: "Страницы категорий",
        in_product_detail_page: "Страницы описания товара",
        in_home_page: "Главная страница",
        in_collection: "Коллекция",
        campaignAdType: "Тип кампании",
        campaignFixedBid: "Фиксированная ставка",
        keywordBid: "Ставка ключевого слова",
        categoryBid: "Ставка категории",
        advertiserResourceID: "ID продавца",
        productSKU: "ID товара",
        customerDeviceEnvironmentType: "Тип устройства клиента",
        campaignDailyBudget: "Дневной бюджет",
        campaignTotalBudget: "Общий бюджет",
        sumVideoStart: "Запуски видео",
        sumVideoComplete: "Завершения видео",
        VCR: "VCR",
        viewabilityRate: "Коэффициент видимости",
        deliveryRate: "Коэффициент доставки",
        adResponseYieldRate: "Коэффициент отклика рекламы",
        fillRate: "Коэффициент заполнения",
        sumPostClickSale: "Продажа после клика",
        sumPostViewSale: "Продажа после просмотра",
        sumPostClickSaleAmount: "Сумма продаж после клика",
        sumPostViewSaleAmount: "Сумма продаж после просмотра",
        sumPostClickDirectSale: "Прямая продажа после клика",
        sumPostClickIndirectSale: "Косвенная продажа после клика",
        sumPostViewDirectSale: "Прямая продажа после просмотра",
        sumPostViewIndirectSale: "Косвенная продажа после просмотра",
        sumPostClickDirectSaleAmount: "Сумма прямых продаж после клика",
        sumPostClickIndirectSaleAmount: "Сумма косвенных продаж после клика",
        sumPostViewDirectSaleAmount: "Сумма прямых продаж после просмотра",
        sumPostViewIndirectSaleAmount: "Сумма косвенных продаж после просмотра",
        ACoSByClick: "ACoS по клику",
        ACoSByView: "ACoS по просмотру",
        RoASByClick: "RoAS по клику",
        RoASByView: "RoAS по просмотру",
        CVRByClick: "CVR по клику",
        CVRByView: "CVR по просмотру",
      },
    },
    states: {
      campaigns_not_started: "Кампания еще не началась.",
      campaigns_date_passed: "Дата окончания кампании прошла.",
      campaigns_status_paused: "Вы приостановили кампанию.",
      campaigns_out_of_daily_budget: "Дневной бюджет вашей кампании исчерпан.",
      campaigns_out_of_total_budget:
        "Ваша кампания достигла лимита общего бюджета.",
      campaigns_products_stopped: "Товары не активны.",
      campaigns_keywords_stopped: "Ключевые слова не активны.",
      campaigns_categories_stopped: "Категории не активны.",
      campaigns_status_archived: "Вы заархивировали кампанию.",
      campaigns_creatives_stopped: "Нет активных креативов.",
      campaigns_insufficient_balance: "Недостаточно баланса.",
      campaigns_low_bid: "Ставка ниже минимальной ставки.",
      campaigns_advertiser_paused: "Рекламодатель не активен.",
      products_status_paused: "Вы приостановили товар.",
      products_low_stock:
        "Статус остановлен из-за недостаточного запаса товара.",
      products_low_rating: "Статус остановлен из-за низкого рейтинга.",
      products_buybox_loser:
        "Статус остановлен из-за отсутствия победы в Buybox.",
      products_product_paused: "Товар не активен.",
      products_product_deleted: "Товар не активен.",
      keywords_status_paused: "Вы приостановили статус ключевого слова.",
      keywords_low_bid: "Ставка ниже минимальной ставки.",
      categories_status_paused: "Таргетинг на категории приостановлен.",
      categories_low_bid: "Ставка ниже минимальной ставки.",
      creatives_paused: "Вы приостановили статус креатива.",
      creatives_pending: "Креатив ожидает проверки.",
      creatives_rejected: "Креатив отклонен.",
      creatives_note: "Причина отклонения:",
    },
    payment: {
      amount: "Сумма к списанию:",
      title: "Метод оплаты",
      subtitle: "Рекламный баланс будет выставлен вам по цене с учетом НДС.",
      wire_transfer: "Банковский перевод / Электронный перевод",
      beymen_milestone_payment: "Платеж по этапам",
      koctas_milestone_payment: "Платеж по этапам",
      modanisa_milestone_payment: "Платеж по этапам",
      bank: "Банковская информация",
      helpUpper:
        "После того как ваш платеж будет одобрен банковским или электронным переводом, он будет зачислен. Процесс утверждения может занять",
      helpSecondary: "2-3 рабочих дня.",
      ref: "Номер ссылки:",
      info: "Очень важно правильно указать номер ссылки в описании транзакции для отслеживания транзакции.",
      tax: "НДС:",
      verifyAmount: "Сумма, которая будет списана с вашего аккаунта:",
      toters_milestone_payment: "Перевод из кошелька",
      product_placeholder: "Искать магазин",
      craftgate: "Кредитная карта",
    },
    tour: {
      prev: "Назад",
      next: "Далее",
      done: "Завершить тур",
      title: "Добро пожаловать на рекламную платформу!",
      intro:
        "Чтобы узнать, как использовать платформу, нажмите «Начать» и продолжите ознакомительный тур по товару. Вы можете пропустить тур, нажав кнопку выхода.",
      menu: "Панель меню",
      menuText:
        "Вы можете использовать панель меню слева для переключения между страницами.",
      campaigns: "Кампании",
      campaignsText:
        "Вы можете управлять своими рекламными кампаниями на странице «Кампании». Здесь вы можете просмотреть количество кликов и продаж, которые вы получили от рекламы.",
      reports: "Отчеты",
      reportsText:
        "Вы можете создавать отчеты, выбирая различные параметры по эффективности ваших реклам. С помощью этих отчетов вы можете просмотреть, сколько раз ваши объявления были показаны, в каких результатах поиска или категориях они отображались или сколько продаж было совершено.",
      reportsTextSub:
        "При создании отчетов вы можете выбрать предварительно определенные отчеты или выбрать пользовательские отчеты, которые вы создадите сами.",
      invoices: "Счета",
      invoicesText:
        "В этом разделе вы можете просмотреть балансы, которые вы загрузили для расходования на свои кампании, подарочные купоны и все расходы на рекламу.",
      academy: "Академия",
      academyText:
        "Здесь вы можете найти необходимую информацию об использовании платформы.",
      widgets: "Статистика",
      widgetsText:
        "Вы можете постепенно просматривать эффективность созданных вами кампаний. Вы также можете подробно изучить эффективность вашей рекламы за день, нажав кнопку «Показать на графике».",
      table: "Таблица кампаний",
      tableText:
        "В этом разделе вы можете просмотреть созданные вами кампании и их эффективность. Вы можете приостановить свои кампании по желанию. Чтобы просмотреть детали вашей кампании, просто нажмите на название кампании.",
      button: "Добавить новую кампанию",
      buttonText:
        "Нажмите кнопку «Добавить новую кампанию», чтобы начать создание вашей первой рекламной кампании.",
      modal: "Тип кампании",
      modalText:
        "Здесь вы можете увидеть типы рекламных кампаний, которые вы можете выбрать.",
      type: "Спонсируемый Товар",
      typeText:
        "Вы можете создать кампанию со спонсируемыми товарами, где вы можете выделить свои товары на первых позициях в результатах поиска или на страницах категорий.",
    },
    widget: {
      title: "Диапазон дат метрик",
      description:
        "Выберите начальную и конечную дату для просмотра ваших метрик. Диапазон по умолчанию — последние 30 дней, и будущие даты выбрать нельзя.",
    },
  },
  pages: {
    acc: {
      campaigns: {
        button: "Добавить новую кампанию",
        placeholder: "Искать кампании",
        errorTitle: "Рекламодатель не выбран",
        errorText: "Пожалуйста, выберите рекламодателя.",
        table: {
          standart: "Автоматическая ставка",
          max_acos: "Автоматическая ставка",
          fixed: "Фиксированная ставка",
          daily: "Ежедневный",
          flexible: "Ежедневный",
          total: "Итоговый",
          budgetLimit: "Ограничено {{value}}",
          stopped: "Вы приостановили кампанию.",
          product: "Спонсируемый Товар",
          display: "Спонсируемый Дисплей",
          store: "Спонсируемый Магазин",
          default: "Просмотр по умолчанию",
          checkUp: "Просмотр проверки",
          video: "Спонсируемое Видео",
          display_with_product: "Спонсируемый Брендовый Дисплей",
          video_with_product: "Спонсируемый Брендовый Видеоролик",
        },
      },
      campaignDetails: {
        subtext: "Вы можете редактировать информацию о кампании.",
        products: "Товары",
        on: "Активно",
        keywords: "Ключевые слова",
        negativeKeywords: "Минус-слова",
        targeting: "Аудитории",
        city: "Город",
        age: "Возраст",
        gender: "Пол",
        platform: "Платформа",
        customers: "Клиенты",
        placements: "Рекламные размещения",
        in_search: "Результаты поиска",
        in_category: "Категории",
        placementPlaceholder: "Поиск рекламного размещения",
        date: "Дата начала-окончания:",
        bid: "Ставка:",
        maxAcos: "Максимальная целевая рекламная стоимость продаж (ACoS)",
        fixed: "Фиксированная ставка",
        standart: "Автоматическая ставка",
        daily: "Ежедневный",
        total: "Итоговый",
        flexible: "Ежедневный",
        none: "Нет",
        keywordsBtn: "Добавить ключевое слово",
        keywordsPlaceholder: "Искать ключевые слова",
        keywordsSub:
          "Добавьте ключевые слова, по которым вы хотите, чтобы ваша реклама отображалась.",
        negative_keywords_btn: "Добавить минус-слова",
        negative_keywords_placeholder: "Искать минус-слова",
        negative_keywords_modal_subtitle:
          "Добавьте ключевые слова, по которым вы не хотите показывать вашу рекламу.",
        negative_categories_btn: "Добавить минус-категорию",
        negative_categories_placeholder: "Искать в минус-категориях",
        negative_categories_modal_subtitle:
          "Добавьте категории, в которых вы не хотите показывать вашу рекламу.",
        productsBtn: "Добавить товары",
        productsPlaceholder: "Искать товары",
        productsSub: "Выберите товары, которые хотите рекламировать.",
        cities_placeholder: "Искать города",
        age_ranges_placeholder: "Искать возрастные диапазоны",
        genders_placeholder: "Искать полы",
        platforms_placeholder: "Искать платформы",
        customers_placeholder: "Искать типы клиентов",
        new: "Новые клиенты",
        present: "Существующие клиенты",
        keywordTitle: "Таргетинг по ключевым словам",
        keywordText:
          "Ключевые слова автоматически выбираются для вашей рекламы. Вы можете отключить ключевые слова, которые не хотите таргетировать.",
        maxAcosLabel: "Целевая рекламная стоимость продаж (ACoS)",
        fixedBidLabel: "Фиксированная ставка",
        productsError: "Необходимо активировать хотя бы 1 товар.",
        keywordsError: "Необходимо активировать хотя бы 1 ключевое слово.",
        categoriesError: "Необходимо активировать хотя бы 1 категорию.",
        categories: "Категории",
        categoriesBtn: "Добавить категорию",
        categoriesPlaceholder: "Поиск по категориям",
        categoriesSub: "Вы можете добавить категории для таргетинга.",
        negativeCategories: "Минус-категории",
        buyboxError: "Товар не является победителем в Buybox.",
        ratingError: "Рейтинг товара ниже минимального.",
        stockError: "Запас товара ниже минимального.",
        notActiveError: "Товар не активен.",
        creatives: "Креативы",
        creativesBtn: "Добавить креативы",
        creativesPlaceholder: "Поиск по креативам",
        creativesSub:
          "Добавьте креативы, которые хотите показать в своей рекламе.",
        creativesLinkTitle: "URL для перенаправления",
        creativesLinkText:
          "Пользователи, которые кликнут на вашу рекламу, будут перенаправлены на {{value}}.",
        winner: "Победитель",
        loser: "Проигравший",
        creativesError: "Необходимо активировать хотя бы 1 креатив.",
        pendingError: "Ожидающие креативы не могут быть активированы.",
        rejectedError: "Отклоненные креативы не могут быть активированы.",
        in_product_detail_page: "Страницы описания товара",
        in_home_page: "Главная страница",
        in_collection: "Коллекция",
        allTimes: "Круглосуточно",
        scheduled: "Запланировано",
        dayParting:
          "Вы можете выбрать дни и часы, когда ваша кампания будет активна.",
        video: "Видео",
        vastTag: "VAST тег",
        videoFile: "Видео файл",
        region: "Регион:",
        regionLength: "{{value}} регионов",
        regionsDrawerTitle: "Обновить регионы",
        languages: "{{value}} языков",
        segments: "Сегменты",
        segments_placeholder: "Поиск сегментов",
        categoryLimitError:
          "Достигнут максимальный лимит. Вы можете добавить до 100 категорий. Приостановите существующую, чтобы добавить новую.",
        keywordLimitError:
          "Достигнут максимальный лимит. Вы можете добавить до 100 ключевых слов. Приостановите существующее, чтобы добавить новое.",
      },
      noMatch: {
        title: "Извините, мы не можем найти эту страницу.",
        btn: "Вернуться на главную страницу",
      },
      reports: {
        button: "Добавить новый отчет",
        placeholder: "Поиск в отчетах",
        name: "Название отчета",
        table: {
          type: "Тип отчета",
        },
        form: {
          title: "Отчет 1",
          goBack: "Вернуться назад",
          create: "Создать отчет",
        },
      },
      reportDetails: {
        result: "Результат отчета",
        static: "Фиксированный",
        scheduled: "Запланированный",
        ready: "Готовый отчет",
        startDate: "Дата начала",
        endDate: "Дата окончания",
        frequency: "Частота",
        firstDate: "Дата первой отправки",
        month: "Первый день месяца",
        emails: "E-mail",
        export: "Тип файла",
        predefined: "Предопределенный тип отчета",
        total: "Получить общую статистику",
      },
      invoice: {
        balance: "Баланс",
        coupon: "Купон",
        lastPayment: "Последнее пополнение баланса",
        searchPlaceholder: "Поиск в счетах",
        duplicateCouponsError:
          "Обнаружено несколько определений купонов. Пожалуйста, подождите.",
        multipleTopUpsError:
          "Обнаружено несколько пополнений баланса. Пожалуйста, подождите.",
        limitError:
          "Вы пытаетесь добавить баланс с высоким значением. Пожалуйста, проверьте сумму.",
        minAmountError:
          "Минимальная сумма платежа должна составлять {{value}}.",
        data: {
          typeBudget: "Пополнение баланса",
          typeAd: "Расходы на рекламу",
          typeBudgetExplanation: "Баланс пополнен.",
          typeAdExplanation: "Рекламные расходы оплачены.",
        },
        table: {
          type: "Тип транзакции",
        },
        modal: {
          button: "Добавить баланс",
          title: "Добавить баланс",
          subtitle:
            "Введите сумму, которую хотите перевести на рекламный баланс.",
          loadingAmount: "Сумма",
          differentAmount: "Введите другую сумму.",
          label: "Сумма",
        },
      },
      academy: {
        more: "Узнать больше",
        copy: "Скопировать ссылку",
        helpTitle: "Не можете найти ответы на свои вопросы?",
        helpText:
          "Для вопросов вы можете связаться с командой поддержки, отправив письмо по электронной почте.",
        contact: "Свяжитесь с нами",
        highlights: "Основные моменты",
        read: "Прочитать остальную часть статьи",
        description:
          "С помощью полезных статей из Руководства по рекламе {{value}} вы можете узнать всё, что вам нужно знать о платформе, и легко начать публиковать свои объявления.",
      },
    },
    admin: {
      advertisers: {
        updateSubtext:
          "Вы можете сгруппировать рекламодателей, назначив их агентствам.",
        modalPlaceholder: "Поиск агентства...",
        search: "Поиск по рекламодателям",
        select_advertiser_subtitle:
          "Выберите рекламодателя, для которого хотите создать кампанию.",
        userSubtext:
          "Вы можете пригласить пользователей, которые будут использовать аккаунт рекламодателя.",
        help: "Добавьте адрес электронной почты пользователя.",
        deleteSuccess: "Агентство успешно удалено.",
        create: "Добавить нового рекламодателя",
        update: "Обновить рекламодателя",
        type: "Тип рекламодателя:",
        custom: "Пользовательский",
        customText:
          "Это тип рекламодателя, созданный с товаром поставщиков, которые настроены для распространения на платформе.",
        name: "Имя рекламодателя",
        nameHelp: "Имя вашего рекламодателя, которое будет отображаться вам.",
        agency: "Агентство",
        agencyHelp: "Агентство, с которым связан рекламодатель.",
        email: "E-mail",
        emailHelp:
          "Введите адрес электронной почты, на который будет отправлено приглашение. Продавец использует введенный вами адрес электронной почты для входа на платформу.",
        product: "Название товара",
        brand: "Бренд",
        retailer_taxonomy: "Категория товара",
        advertiser_id: "ID рекламодателя",
        filters: "Фильтры:",
        filterWarning: "Пожалуйста, добавьте хотя бы один фильтр.",
        typeTitle: "Тип рекламодателя",
        typeSubtext:
          "Какой из следующих типов вы хотите изменить для выбранного рекламодателя?",
        default: "По умолчанию",
        defaultText:
          "Используется для аккаунтов продавцов, которые продают напрямую под своим именем на платформе.",
        shareable: "Распространяемый",
        shareableText:
          "Используется для аккаунтов продавцов, которые продают от имени других лиц на платформе и должны распределяться между основными продавцами.",
        generalTitle: "Общая информация",
        generalSubtext: "Общая информация о рекламодателе",
        successText:
          "Все товары, соответствующие бренду выбранного вами продавца, были добавлены.",
        advertiser: "Рекламодатель:",
        drawerFilterWarning: "Добавленные фильтры не могут быть отменены!",
        filterTitle: "Фильтр",
        filterText:
          "Выберите фильтр, который хотите применить к рекламодателям.",
        keyLabel: "Выберите фильтр",
        opLabel: "Выберите условие",
        valueLabel: "Введите значение",
        categorySelectLabel: "Выберите категорию",
        filterKeyError: "Пожалуйста, выберите фильтр.",
        filterOpError: "Пожалуйста, выберите условие.",
        filterValueError: "Пожалуйста, введите значение.",
        filterCategoryError: "Пожалуйста, выберите категорию.",
      },
      advertiserSettings: {
        title: "Настройки рекламодателя",
        subtext: "Настройки, связанные с рекламодателями.",
        status: "Статус рекламодателя по умолчанию",
        statusText:
          "Если статус рекламодателя по умолчанию закрыт, вы должны активировать статус на странице рекламодателей, чтобы они могли войти в систему.",
        welcome: "Приветственный купон",
        welcomeText:
          "Вы можете определить приветственный купон, который рекламодатель сможет использовать при первом заходе на платформу.",
        warning:
          'Если вы введете "0", приветственный купон не будет определен для рекламодателей.',
        label: "Баланс купона",
        language: "Язык",
        languageText:
          "Это язык интерфейса по умолчанию для рекламодателей. Рекламодатели могут изменить выбор языка через свой собственный интерфейс.",
        balance: "Авторизация загрузки баланса",
        balanceText:
          "Это право рекламодателей переводить свои авансовые платежи на свои рекламные балансы через интерфейс.",
        open: "Открыто",
        closed: "Закрыто",
        error_title: "Вы должны ввести значение.",
        error_text:
          'Если вы не хотите определять купон, вы можете ввести "0" и нажать кнопку "Сохранить".',
        error_text_input: "Вы должны ввести значение.",
      },
      agencies: {
        label: "Название агентства",
        subtext: "Введите название агентства",
        subtitle: "Вы можете добавить агентство для группировки рекламодателей",
        search: "Поиск по агентствам",
        edit: "Обновить название агентства",
      },
      auctionSettings: {
        title: "Настройки аукциона",
        subtext: "Настройки, связанные с аукционами",
        type: "Тип аукциона",
        typeText:
          "Выберите, по какому типу аукциона будут оцениваться ставки рекламодателей. Подробную информацию о типах аукционов можно найти здесь.",
        warning: "Изменения повлияют на расходы на рекламу на платформе.",
        firstOpt: "Аукцион первой цены",
        firstOptText: "Рекламодатели-победители платят предложенную ставку.",
        secondOpt: "Аукцион второй цены",
        secondOptText:
          "Рекламодатели-победители платят на 1 цент больше, чем вторая по величине ставка.",
        link: "Типы аукционов",
      },
      campaignSettings: {
        title: "Настройки кампаний",
        subtext: "Настройки, связанные с настройкой кампаний",
        conversionRange: "Диапазон конверсий",
        conversionRangeText:
          "Вы можете указать период времени, в течение которого покупка пользователя будет приписана клику по рекламе. Когда интервал конверсий установлен на 7 дней, если пользователь нажимает на рекламу и покупает товар в течение 7 дней, баланс кампании будет потрачен.",
        product: "Рейтинг товара",
        productText:
          "Вы можете установить минимальное требование к рейтингу товаров, которые рекламодатели добавят в свои кампании, и настроить поведение для товаров, не соответствующих этому условию.",
        minimum: "Минимальный рейтинг товара",
        minimumText:
          "Введите минимальный рейтинг товаров, добавляемых в кампании.",
        behaviour: "Задать поведение",
        behaviourText:
          "Введите минимальный рейтинг для товаров, добавляемых в кампании.",
        placeholder: "Выбрать поведение",
        stock: "Запас товара",
        stockText:
          "Вы можете установить минимальное требование к запасам для товаров, которые рекламодатели добавят в свои кампании, и настроить поведение для товаров, не соответствующих этому условию.",
        minimumStock: "Минимальный запас товара",
        minimumStockText:
          "Введите минимальный запас для товаров, добавляемых в кампании.",
        stockBehaviourText:
          "Определите поведение, которое будет происходить, когда количество запаса товаров упадет ниже минимального запаса, который вы указали.",
        alert: "Разрешить в кампании и показывать объявления",
        block: "Не разрешать в кампании",
        targeting: "Демографическое таргетирование",
        targetingText:
          "При настройке кампании выберите, нужно ли включить этап демографического таргетирования. Когда он включен, в настройку кампании добавляется дополнительный шаг, позволяющий рекламодателям устанавливать свои предпочтения по таргетированию.",
        all: "Все рекламодатели",
        none: "Ни один рекламодатель",
        selected: "Выбранные рекламодатели",
        format: "Форматы реклам",
        sponsored: "Спонсируемыей Товар",
        brand: "Спонсируемый Бренд",
        ad: "Спонсируемый Дисплей",
        rating_warn:
          "Ваш рейтинг товара слишком низкий! Добавление этого товара ухудшит эффективность кампании.",
        rating_block:
          "Ваш рейтинг товара слишком низкий! Вы не можете добавить этот товар в кампанию!",
        stock_warn:
          "Ваш запас товара слишком мал! Добавление этого товара ухудшит эффективность кампании.",
        stock_block:
          "Ваш запас товара слишком мал! Вы не можете добавить этот товар в кампанию!",
        buybox_warn:
          'Товар не является победителем "Buy Box". Добавление этого товара ухудшит эффективность кампании.',
        buybox_block:
          'Товар не является победителем "Buy Box". Вы не можете добавить этот товар в кампанию!',
        buyBoxTitle: "Условие Buy Box",
        buyBoxText:
          'Вы можете добавить условие "Buy Box" для товаров, которые рекламодатели будут добавлять в свои кампании.',
        buyBoxSubtext:
          'Определите поведение, которое будет происходить, когда товары, добавляемые в кампанию, не будут победителями "Buy Box".',
        noWorning: "Нет",
        warn: "Изменение повлияет на все активные кампании.",
        rangeError:
          "Вы должны ввести значение. Рекомендуемое значение — 7 дней.",
        rangeErrorInput: "Можно ввести только значение от 1 до 14 дней.",
        chooseBehaviour: "Вы должны выбрать поведение.",
        stockError: "Вы должны ввести минимальный запас товара.",
        allStockError:
          "Вы должны ввести минимальный запас товара и выбрать поведение.",
        ratingError: "Вы должны ввести минимальный рейтинг.",
        allRatingError:
          "Вы должны ввести минимальный рейтинг и выбрать поведение.",
        serverBlock: "Разрешить в кампании, но блокировать объявления",
        advertisers: "Выбранные рекламодатели",
        advertisersText:
          "Демографическое таргетирование будет доступно только выбранным рекламодателям.",
        advertisersBtn: "Добавить рекламодателя",
        advertisersSub:
          "Выберите рекламодателей, которые смогут использовать демографическое таргетирование.",
        advertisersPlaceholder: "Выберите рекламодателя",
        advertisersLength: "Выбрано рекламодателей: {{value}}",
        advertisersTitle: "Рекламодатели",
        general: "Общее",
        bid: "Ограничения ставок",
        conversion: "Конверсии",
        conversionSubtext:
          "Настройки, связанные с вашими целями по конверсиям на платформе",
        biddingTypes: "Доступные типы ставок для рекламируемых товаров",
        biddingTypesText:
          "На этапе создания кампании вы можете указать типы ставок, которые могут использовать рекламодатели.",
        biddingTypesWarning: "Хотя бы одна опция должна оставаться включенной.",
        auto: "Автоматическая ставка",
        autoText:
          "Тип ставки, при котором ставка определяется автоматически. Расходы рассчитываются с учетом цены проданного товара и установленного целевого ACoS.",
        acos: "Целевая рекламная стоимость продаж (ACoS)",
        acosText:
          "Ставка рассчитывается автоматически на основе введённой рекламодателем ставки для рекламной стоимости продаж (ACoS) и цены проданных товаров.",
        fixed: "Фиксированная ставка",
        fixedText:
          "Ставка определяется рекламодателем. Рекламодатели могут установить ставку для всей кампании за ключевое слово и категорию.",
        bidWarning: "Необходимо выбрать хотя бы один тип ставки.",
        bidText: "Настройки для правил ставок на платформе",
        bidLabelTitle: "Общая минимальная цена за клик (CPC)",
        bidLabelText:
          "Минимальная ставка за клик, которую необходимо ввести для всех категорий и ключевых слов.",
        bidLabel: "Минимальная цена за клик (CPC)",
        cpmTitle: "Общая минимальная цена за тысячу показов (CPM)",
        cpmText:
          "Минимальная ставка за показ, которую необходимо ввести для всех категорий и ключевых слов.",
        cpmLabel: "Минимальная цена за тысячу показов (CPM)",
        targetAcosTitle: "Общая целевая рекламная стоимость продаж (ACoS)",
        targetAcosText:
          "Вы можете установить максимальный процент рекламной стоимости продаж, нацеленный по умолчанию на аукционах.",
        targetAcosLabel: "Макс. рекламная стоимость продаж (ACoS)",
        categoryRelevanceTitle: "Общая релевантность категорий",
        categoryRelevanceText:
          "Определяет уровень релевантности между рекламируемыми товарами и органическими товарами, отображаемыми на рекламных показах.",
        categoryRelevanceLabel: "Релевантность категорий",
        bidTabWarning:
          "Изменения повлияют на расходы на рекламу и показы на платформе.",
        inputErrorZero: "Вы должны ввести значение больше нуля.",
        inputErrorAcos: "Вы должны ввести значение от 0 до 100.",
        inputErrorRelevance: "Вы должны ввести целое число, больше нуля.",
        inputError: "Вы должны ввести значение.",
        productLink: "Ограничение рейтинга товара",
        stockLink: "Ограничение запаса товаров",
        targetingLink: "Функция демографического таргетирования",
        bidLink: "Типы ставок",
        minThreshold: "Минимальный порог продаж",
        minThresholdText:
          "Это минимальный порог продаж для товаров, включаемых в аукцион.",
        minThresholdLabel: "Минимальный порог продаж",
        minThresholdError: "Пожалуйста, введите целое число.",
        formatTitle: "Настройки атрибуции по формату",
        formatText:
          "Точки взаимодействия измеряются для определения их эффективности в различных форматах кампаний. В зависимости от формата, выбор показов отслеживает видимость, тогда как клики фокусируются на измерении конверсий через прямое взаимодействие.",
        sponsoredProductTitle: "Спонсируемый Товар",
        sponsoredProductText:
          "Для рекламных объявлений товаров конверсии оцениваются на основе того, насколько эффективно пользователи взаимодействуют с рекламой, с акцентом на влияние вовлеченности или экспозиции на желаемый результат.",
        sponsoredDisplayTitle: "Спонсируемый Дисплей",
        sponsoredDisplayText:
          "Медийные объявления измеряют конверсии путем анализа того, оказывает ли сама видимость влияние на действия пользователей или требуется более глубокое взаимодействие.",
        sponsoredVideoTitle: "Спонсируемое Видео",
        sponsoredVideoText:
          "В случае видеорекламы измерение конверсий сосредоточено на определении того, способствует ли достижению цели простое просмотрение контента или активное взаимодействие.",
        postView: "После просмотра",
        postClick: "После клика",
        reportTitle: "Настройки атрибуции по отчетности",
        reportText:
          "Этот параметр определяет, как атрибуция применяется в отчетах, формируя структуру организации и представления данных на основе выбранного подхода.",
        marketplaceReport: "Раздельные метрики в отчете маркетплейса",
        marketplaceReportText:
          "Включение этой опции разделяет метрики в отчете маркетплейса на основе атрибуции, обеспечивая более четкое понимание эффективности каждой точки взаимодействия.",
        advertiserReport: "Раздельные метрики в отчете рекламодателя",
        advertiserReportText:
          "Активация этой опции разделяет метрики в отчете рекламодателя по атрибуции, помогая лучше понять влияние различных взаимодействий с кампанией.",
        widgetTitle: "Настройка атрибуции для виджета",
        widgetText:
          "Атрибуция виджета оценивает пользовательские взаимодействия на протяжении всего пути к конверсии. Выбор отслеживания показов фиксирует видимость рекламы, тогда как отслеживание кликов измеряет вовлеченность как индикатор конверсии.",
      },
      console: {
        add: "Добавить новый источник данных",
        advertiser: "Рекламодатель",
        product: "Продукт",
        google_feed_url_scheduler: "URL Google фида",
        google_feed_file: "Загрузка файла Google фида (XML)",
        google_feed_body: "Прямой ввод XML Google фида",
        meta_feed_url_scheduler: "URL Meta фида",
        minutes_15: "15 минут",
        minutes_30: "30 минут",
        hour_1: "1 час",
        hours_2: "2 часа",
        hours_3: "3 часа",
        hours_4: "4 часа",
        hours_6: "6 часов",
        hours_12: "12 часов",
        hours_24: "24 часа",
        history: "Показать историю",
        logs: "Показать журналы",
        drawer: "Просмотр журнала",
        error: "Ошибка",
        warning: "Предупреждение",
        success: "Успех",
        info: "Информация",
        prev: "Предыдущие 50 записей",
        next: "Следующие 50 записей",
        api: "API",
        impression: "Показы",
        click: "Клик",
        sale: "Продажа",
        test: "Тест",
        curlTest: "Тестирование",
        curlWarning:
          "Важно: избегайте выполнения этого процесса в производственной среде!",
        request: "Запрос",
        run: "Запустить",
        response: "Ответ",
        hide: "Скрыть необработанные данные",
        show: "Показать необработанные данные",
        auctionLogs: "Журналы аукциона",
      },
      coupons: {
        add: "Определить купон",
        title: "Информация о рекламодателе",
        subtext:
          "Вы можете определить купоны для рекламодателей, которые будут использоваться для кампаний.",
        label: "Дата истечения срока действия",
        warning:
          "Рекламодатели должны активировать купон до введенной даты. Если вы не введете срок действия, купон будет добавлен на баланс рекламодателя навсегда, и рекламодатель сможет использовать его в любое время.",
        gift_coupon: "Подарочный купон",
        welcome_coupon: "Приветственный купон",
        gift_coupon_desc: "Определен подарочный купон.",
        welcome_coupon_desc: "Определен приветственный купон.",
        approve: "Хотите продолжить определение купона?",
        note: "Описание купона",
        noteHelp:
          "Укажите причину определения купона. Например, Купон для Черной пятницы.",
      },
      creatives: {
        all: "Все",
        accepteds: "Принятые",
        rejecteds: "Отклоненные",
        pendings: "Ожидающие",
        pending: "Ожидает",
        accepted: "Принято",
        rejected: "Отклонено",
        adModalTitle: "Предварительный просмотр рекламы",
        creativeModalTitle: "Предварительный просмотр креатива",
        creative: "Креатив",
        name: "Название креатива",
        search: "Поиск по креативам",
        videoModalTitle: "Предварительный просмотр видео",
        rejectSubtitle:
          "Вы можете ввести причину отклонения креатива или оставить это поле пустым и продолжить.",
        note: "Введите причину отклонения креатива.",
      },
      generalInfo: {
        title: "Общая информация",
        subtext: "Общая информация о маркетплейсе",
        market: "Название маркетплейса",
        marketText:
          "Название магазина, которое отображается в интерфейсе. Для изменения этой информации нужно связаться с менеджером по аккаунту.",
        url: "URL",
        urlText:
          "Это ссылка на магазин, используемая в интерфейсе и для маршрутизации. Для изменения этой информации нужно связаться с менеджером по аккаунту.",
        country: "Страна",
        countryText: "Страна, используемая для таргетирования по городам.",
        zone: "Часовой пояс",
        zoneText:
          "Это часовой пояс по умолчанию, который используется в интерфейсах. Для изменения этой информации нужно связаться с менеджером по аккаунту.",
        currency: "Валюта",
        currencyText:
          "Валюта, используемая в интерфейсах. Эта информация не может быть изменена.",
        zoneIstanbul: "Европа/Стамбул",
        email: "Адрес электронной почты службы поддержки",
        emailText:
          "Это адрес электронной почты, с которым будут связываться ваши продавцы.",
        emailLabel: "E-mail",
        emailError: "Вы должны ввести адрес электронной почты.",
        seller: "Панель продавца",
        sellerText:
          "Это адрес, который позволит вашим продавцам направлять напрямую в вашу панель через интерфейс.",
        sellerLabel: "Панель продавца",
        marketError: "Вы должны ввести название маркетплейса.",
        urlError: "Вы должны ввести URL.",
      },
      inspect: {
        campaign: "Кампания:",
        advertiser: "Рекламодатель:",
        url: "URL:",
        size: "Размер:",
        status: "Статус:",
        keywords: "Ключевые слова",
        categories: "Категории",
        vastTag: 'VAST "Тег":',
      },
      integrationInfo: {
        title: "Информация по интеграции",
        subtext:
          "Информация по интеграции, необходимая для выполнения передачи данных",
        api: "Код безопасности API",
        apiText:
          "Это необходимый код безопасности для доступа к платформе через API.",
        ftp: "Сертификат безопасности FTP",
        ftpText: "Это необходимый сертификат для доступа к серверу FTP.",
        download: "Загрузить",
        copy: "Нажмите, чтобы скопировать.",
        copied: "Скопировано",
        auto: "Авто показ",
        autoText:
          "Это настройка, которая позволяет автоматически записывать все показы без интеграции события. Когда настройка включена, для каждого товара записывается метрика показа, предполагая, что все товары, возвращенные в ответе на рекламу, отображаются на вашем сайте. Когда настройка выключена, для каждого показа должно быть отправлено событие запроса в соответствии с желаемым форматом.",
        link: "Событие показа",
        apiLink: "API Endpoints",
      },
      transactions: {
        subtext:
          "Вы можете определить балансы, которые рекламодатели будут использовать для своих рекламных расходов.",
        warning: "Вы можете добавить номер счета.",
        balance_updated: "Пополнение баланса",
        gift_coupon: "Подарочный купон",
        welcome_coupon: "Приветственный купон",
        refund: "Возврат",
        spend: "Расходы на рекламу",
        ad_balance_loaded_desc: "Баланс пополнен.",
        spend_desc: "Расходы на рекламу произведены.",
        gift_coupon_desc: "Подарочный купон заработан.",
        welcome_coupon_desc: "Приветственный купон заработан.",
        ad_balance_refunded_desc: "Баланс пополнен.",
        credit_card_desc: "Баланс обновлен через кредитную карту.",
        detailsSubtext:
          "Вы можете просмотреть детали своих расходов на рекламу.",
        spendTab: "Расходы на рекламу",
        budgetTab: "Источники бюджета",
        advertiser_budget: "Баланс",
        approve: "Хотите продолжить добавление баланса?",
        advertiser: "Рекламодатель:",
        balance: 'Баланс для "загрузки":',
        update: "Вы можете добавить или отредактировать номер счета.",
        title: "Информация о счете",
        payment: "Платеж",
        success: "Успех",
        waiting: "Ожидание",
        failure: "Неудача",
        invoiceWarning: "Вы можете ввести номер счета до 30 символов.",
        note: "Примечание по загрузке баланса",
        noteHelp:
          "Укажите причину пополнения баланса. Например, баланс за Август.",
        risk: "Это действие связано с высоким риском!",
        riskDesc:
          "Возврат баланса может повлиять на ежедневные расходы рекламодателя и привести к отрицательному балансу. Прежде чем продолжить, убедитесь, что все кампании рекламодателя приостановлены.",
        refundTitle: "Это действие необратимо!",
        refundDescription:
          "Указанная сумма будет немедленно вычтена из баланса рекламодателя. Возврат средств без проверки ежедневных расходов рекламодателя может привести к отрицательному балансу и потенциальным проблемам.",
        selectAdvertiserHelp:
          "Выберите рекламодателя, который получит возврат.",
        refundAmount: "Сумма",
        verifyRefundAmount: "Подтвердите сумму",
        refundAmountHelp:
          "Укажите точную сумму возврата. Будьте осторожны, так как это действие необратимо.",
        verifyRefundAmountHelp: "Подтвердите сумму возврата.",
        refundNote: "Примечания",
        refundNoteHelp:
          "В этом поле можно добавить заметку о причине возврата. Это может быть полезно для отслеживания и уточнения причины возврата в ваших записях.",
        amountToRefund: "Сумма к возврату:",
        check1:
          "Я подтверждаю, что рекламодатель не потратил никаких средств за последние 24 часа.",
        check2:
          "Я проверил, что все кампании рекламодателя были приостановлены не менее чем на 24 часа.",
        check3:
          "Я понимаю, что этот возврат вступает в силу немедленно и не может быть отменен.",
        check4:
          "Я признаю, что если этот возврат приведет к отрицательному балансу, я беру на себя полную ответственность за последствия.",
        check5:
          "Я подтверждаю, что у меня есть необходимая авторизация для обработки этого возврата от имени рекламодателя.",
        refundSuccessTitle: "Возврат успешно обработан!",
        refundSuccessText:
          "Средства были успешно списаны с аккаунта рекламодателя. Если вы заметите какие-либо проблемы, проверьте активность аккаунта рекламодателя или свяжитесь со службой поддержки.",
        refundErrorTitle: "Ошибка возврата",
        refundErrorText:
          "В настоящее время возврат не может быть обработан. Возможно, возникла непредвиденная проблема.",
        negativeRefundErrorTitle: "Возврат не выполнен",
        negativeRefundErrorText:
          "Сумма возврата превышает ваш доступный баланс. Пожалуйста, введите сумму, равную или меньшую вашему текущему балансу.",
      },
      users: {
        label: "E-mail",
        subtext: "Для подтверждения приглашения будет отправлено письмо.",
        invite:
          "Введите адрес электронной почты, на который будет отправлено приглашение.",
        warn: "Вы не можете остановить свою учетную запись.",
        error: "Вы добавили существующий адрес электронной почты.",
        search: "Поиск по пользователям",
        select: "Выберите тип пользователя, которого хотите добавить.",
        advertiser: "Рекламодатель",
        advertiserText:
          "Только выбранный вами аккаунт рекламодателя может получить доступ; это лицо, которое будет управлять рекламной деятельностью.",
        marketplace: "Администратор",
        marketplaceText:
          "Имеет вес доступ на рекламной платформе, может предоставлять доступ пользователям или управлять им.",
        limited: "Ограниченный доступ",
        full: "Полный доступ",
      },
      invited: {
        error:
          "Приглашение может быть отправлено на один и тот же почтовый ящик для одной и той же учетной записи не более одного раза каждые 10 минут.",
        success: "Пользователь был повторно приглашен.",
        search: "Поиск по приглашенным пользователям",
        title: "Детали приглашения",
        info: "Общая информация",
        mail: "E-mail:",
        advertiser: "Рекламодатель:",
        urlTitle: "Ссылка на приглашение",
        link: "Как добавить пользователя рекламодателя?",
        single: "Один пользователь",
        multiple: "Массовое приглашение",
        advertiserText: "Выберите рекламодателя.",
        draggerTitle:
          "Выберите файл <strong>.CSV</strong> для загрузки массовой почты",
        draggerText: "или перетащите файл сюда.",
        csvError: "Произошла ошибка при загрузке файла.",
        csvInfo:
          "Вы можете массово приглашать пользователей в разные аккаунты рекламодателей, используя CSV файл. Вы можете получить информацию о формате CSV файла, используя ссылку ниже.",
        restriction: "Ограниченный доступ",
        restrictionText:
          "Когда вы включаете эту опцию, пользователь получает только ограниченный доступ.",
        edit: "Редактировать",
        role: "Роль:",
        MARKETPLACE: "Администратор",
        MARKETPLACE_REPORTER: "Администратор-отчетник",
        ADVERTISER: "Рекламодатель",
        ADVERTISER_REPORTER: "Рекламодатель-отчетник",
        archived:
          "Нельзя отправлять приглашения архивированным рекламодателям.",
      },
      sync: {
        file: "Файл",
        api: "API",
        feed: "Фид",
        products_created: "Товары созданы.",
        products_updated: "Товары обновлены.",
        products_deactivated: "Товары деактивированы.",
        products_activated: "Товары активированы.",
        missing_advertisers: "Рекламодатели отсутствуют.",
        advertisers_created: "Рекламодатели созданы.",
        advertisers_updated: "Рекламодатели обновлены.",
        advertisers_deactivated: "Рекламодатели деактивированы.",
        advertisers_activated: "Рекламодатели активированы.",
        categories_created: "Категории созданы.",
      },
      placement: {
        add: "Добавить новое размещение рекламы",
        display: "Спонсируемый Дисплей",
        product: "Спонсируемый Товар",
        update: "Обновить размещение рекламы",
        settings: "Общие настройки",
        settingsText: "Общие настройки вашего рекламного размещения",
        name: "Название рекламного размещения",
        nameText: "Это название видно только вам.",
        format: "Формат рекламы",
        formatText:
          "Формат, в котором будет отображаться ваше рекламное размещение",
        sponsoredText:
          "Отображение товара по соответствующим ключевым словам и категориям",
        displayText:
          "Отображение дисплей рекламы по соответствующим ключевым словам и категориям",
        suggestedTitle: "Рекомендуемые размеры",
        suggestedText:
          "Фактический размер вашего рекламного размещения. Этот размер будет отображаться рекламодателями.",
        suggestedError: "Можно добавить до одного размера.",
        acceptedTitle: "Допустимые размеры",
        acceptedText:
          "Другие размеры рекламы, которые могут появиться в вашем рекламном размещении. Рекламодатели увидят размеры.",
        acceptedError: "Пожалуйста, выберите размер рекламы.",
        formatError: "Пожалуйста, выберите формат.",
        cross_sell: "Кросс-продажа",
        crossText:
          "Запрещено показывать рекламу на страницах с соответствующими товарами и отображать указанные товары в виде рекламы.",
        maxAds: "Максимальное количество объявлений",
        maxAdsText:
          "Максимальное количество объявлений, которое может быть размещено.",
        crossTitle: "Поведение блокировки рекламы",
        crossSub:
          "Фактический размер вашего рекламного размещения. Этот размер будет отображаться рекламодателями.",
        crossPlaceholder: "Выберите тип.",
        crossProduct: "Блокировать указанный товар",
        crossBlock: "Не блокировать",
        crossError: "Выберите поведение.",
        adPlacementType: "Тип размещения рекламы",
        adPlacementTypeText:
          "Выберите тип страницы или размещения, где будет отображаться ваша реклама. Этот выбор также будет использоваться на этапе таргетинга размещения рекламы в созданных кампаниях.",
        typeError: "Пожалуйста, выберите тип страницы.",
        adPlacementTypePlaceholder: "Выберите тип страницы",
        search: "Результаты поиска",
        category: "Страницы категорий",
        homepage: "Домашняя страница",
        detail: "Карточка товаров",
        collection: "Коллекция",
        video: "Спонсируемое Видео",
        videoText:
          "Отображение спонсируемого видео по соответствующим ключевым словам и категориям",
        minAds: "Минимальное количество объявлений",
        minAdsText:
          "Минимальное количество объявлений, которое может быть размещено.",
        minBid: "Минимальная ставка",
        minBidText: "Это минимальная сумма ставки для данного размещения.",
        videoWarning:
          "Максимальное количество объявлений для видеорекламы – <strong>“1”</strong>.",
        promoteText:
          "Вы можете добавить только 1 объявление при включенном продвижении товаров.",
        displayWithProduct: "Спонсируемый Брендовый Дисплей",
        videoWithProduct: "Спонсируемый Брендовый Видеоролик",
        display_with_product: "Спонсируемый Брендовый Дисплей",
        video_with_product: "Спонсируемый Брендовый Видеоролик",
        archive_confirmation_text: "Архивирование {{name}} удалит его из активного списка. Оно будет исключено из показа рекламы.",
        archive_checkbox_text: "Я понимаю и хочу продолжить."
      },
      restrictions: {
        category: "Категория",
        keyword: "Ключевое слово",
        categoryTitle: "Ставка и Рекламная Стоимость Продаж для категории",
        categoryText:
          "Вы можете ввести минимальную ставку и целевую Рекламную Стоимость Продаж, которые будут действовать только для указанных вами категорий, и настроить релевантность категории. Настройки, которые вы сделаете в этом поле, имеют приоритет над общими настройками.",
        keywordTitle: "Черный список ключевых слов",
        keywordText:
          "Вы можете ограничить показ реклам по указанным ключевым словам. В результатах поиска, ограниченных эксклюзивным типом, рекламируются только товары, содержащие это слово. Объявления не отображаются в результатах поиска, ограниченных типом блокировки.",
        placeholder: "Поиск по ключевым словам",
        add: "Добавить новое ключевое слово",
        info: "Общая информация",
        label: "Ключевое слово",
        help: "Ключевое слово, по которому вы хотите ограничить результаты поиска",
        type: "Тип ограничения",
        typeText:
          "Тип ограничения, которое будет применяться к результатам поиска с вашим ключевым словом",
        exclusive: "Эксклюзивный",
        exclusiveText:
          "В результатах поиска рекламируются только товары с вашим ключевым словом.",
        block: "Блокировка",
        blockText: "Все рекламы заблокированы в результатах поиска.",
        all: "Показать все",
        edited: "Показать отредактированные категории",
        expand: "Развернуть все",
        collapse: "Свернуть все",
      },
    },
    auth: {
      title: "{{value}} Платформа рекламы",
      accounts: {
        title: "Выберите аккаунт",
        text: "Выберите аккаунт, в который хотите войти.",
        logout: "Выйти",
        noAccount: "Аккаунт не активен!",
        noAccountText:
          "Похоже, у вас нет активных аккаунтов. За помощью обратитесь к вашему менеджеру по аккаунту через <strong>{{value}}</strong>",
        noAccountTextNoEmail:
          "Похоже, у вас нет активных аккаунтов. За помощью обратитесь к вашему менеджеру по аккаунту.",
        contact: "Связаться",
        login: "Вернуться на домашнюю страницу",
      },
      login: {
        title: "Вход",
        text: "С возвращением! Пожалуйста, введите свои данные.",
        remember: "Запомнить меня",
        forget: "Забыл пароль?",
        button: "Войти",
        mail: "Email",
        password: "Пароль",
        mailErr: "Пожалуйста, введите свой email.",
        passwordErr: "Пожалуйста, введите свой пароль.",
        incorrectPassword: "Ваш пароль неверен!",
        incorrectPasswordText: "Пожалуйста, попробуйте снова.",
        notActive: "Ваш аккаунт был приостановлен.",
        notActiveText: "Пожалуйста, свяжитесь с командой поддержки.",
        multipleAccountErr:
          "Произошла ошибка при создании новой учетной записи.",
        notHaveAnAccount: "Нет аккаунта?",
        signup: "Зарегистрироваться",
        linkButton: "Отправить ссылку для входа",
        success: "Ссылка для входа была отправлена на вашу электронную почту.",
        successText:
          "Нажмите на ссылку в полученном письме, чтобы войти в ваш аккаунт.",
        error: "Произошла ошибка при входе в систему.",
        errorText: "Пожалуйста, попробуйте снова.",
        invalidEmail: "Неверный адрес электронной почты.",
        invalidEmailText:
          "Пожалуйста, введите действительный адрес электронной почты.",
        userNotFound: "Пользователь с этим адресом не найден.",
        userNotFoundText:
          "Пожалуйста, проверьте адрес электронной почты и попробуйте снова.",
        verificationError:
          "Мы не смогли проверить ваш запрос. Пожалуйста, попробуйте снова.",
      },
      onboarding: {
        help: "Помощь",
        login: "Войти",
        to: "на",
        retail: "Внедрить функцию ритейл медиа",
        infrastructure: "инфраструктуру за <strong>несколько минут.</strong>",
        linkedInButton: "Зарегистрироваться с LinkedIn",
        linkedInTextAccept: "согласившись с <1>условиями и положениями.</1>",
        firstStepText:
          "Вы можете внедрить функцию ритейл медиа на свою платформу всего за несколько минут, без необходимости в технической доработке или длительных интеграциях. Уже спустя короткое время ваши продавцы смогут размещать рекламу на вашем маркетплейсе.",
        changeAccount: "Сменить аккаунт",
        createTitle: "Создайте свою панель",
        createSubtitle: "Введите информацию о платформе и завершите настройку!",
        websiteLabel: "URL сайта компании",
        websiteHelp: "Введите URL сайта компании.",
        passwordLabel: "Пароль",
        passwordHelp: "Пароль должен содержать не менее 8 символов.",
        typeLabel: "Тип примера данных",
        typeHelp:
          "Выберите тип примера данных, который будет добавлен на платформу.",
        clothing: "Одежда",
        tech: "Техника",
        grocery: "Товары",
        continue: "Продолжить",
        thirdStepText:
          "Настройте таргетинг, ставки и бюджет для вашей кампании с легкостью и запустите свою кампанию!",
        campaignTitle: "Создайте свою первую кампанию",
        campaignText:
          "Создайте свою первую медийную кампанию, загрузив изображение. Если у вас его нет, вы можете использовать пример изображения.",
        autoTitle: "Автоматическое размещение",
        autoText:
          "Реклама автоматически открываются в верхней части вашего веб-сайта, без необходимости в кодировании.",
        autoTag: "Подходит для пользователей без технических навыков.",
        customTitle: "Настраиваемое размещение",
        customText:
          "Выберите размещение и размер рекламы. Требуется базовое кодирование.",
        customTag: "Подходит для пользователей с техническими навыками.",
        sampleBtn: "Использовать пример изображения",
        uploadBtn: "Использовать собственное изображение",
        sampleText:
          "Следующее изображение будет использовано в качестве примера в вашем рекламном предпочтении.",
        uploadText: "Разрешен только размер изображения 970x50px!",
        uploadInnerTitle: "Загрузите файл <strong>.JPG, .PNG</strong>!",
        uploadInnerText: "или перетащите файл сюда.",
        errorTitle: "Ошибка изображения!",
        errorText:
          "Пожалуйста, загрузите изображение, соответствующее указанным размерам!",
        errorBtn: "Загрузить новое изображение",
        errorWarning:
          "Изображение, которое вы загрузили, должно иметь размер 970x50px!",
        create: "Создать кампанию",
        fourthStepText:
          "Без усилий показывайте рекламу на своем сайте, встроив сгенерированный скрипт в код вашего сайта!",
        embedCodeTitle: "Вставьте код на свой сайт",
        embedCodeText:
          "Интегрируйте код ниже в HTML вашего сайта или через диспетчер тегов, чтобы запустить вашу рекламу.",
        pending: "Ожидание трафика",
        live: "В эфире",
        activityTitle: "Активность скрипта:",
        connectionText:
          "Если соединение не установлено автоматически, нажмите кнопку!",
        checkConnection: "Проверить соединение",
        finishOnboarding: "Перейти на панель",
        redirectURL: "URL перенаправления вашей рекламы",
        redirectHelp:
          "Пожалуйста, укажите URL, куда пользователи будут перенаправлены после нажатия на вашу рекламу.",
        sampleBanner: "Использовать пример изображения",
        sampleBannerText:
          "Ваша реклама будет отображаться на вашем сайте следующим образом.",
      },
      signUp: {
        title: "Регистрация",
        text: "Добро пожаловать, {{value}}",
        password: "Пароль",
        passwordErr: "Пожалуйста, введите свой пароль.",
        warning:
          "Введите пароль длиной не менее 8 символов, включающий числа, знаки препинания, заглавные и строчные буквы.",
        already: "У вас уже есть аккаунт?",
        successBtn: "Войти",
        success: "Регистрация успешна!",
        successText:
          "Ваша регистрация с {{value}} почтой успешно завершена. Нажмите кнопку входа, чтобы продолжить на панель.",
        help: "Чем мы можем помочь?",
        err: "Пожалуйста, введите действительный пароль.",
        userExistErr:
          "Этот пользователь уже существует. Пожалуйста, войдите, чтобы увидеть доступные вам аккаунты.",
        privacyErr:
          "Чтобы завершить регистрацию, пожалуйста, примите все необходимые соглашения.",
        error: "Приглашение не найдено.",
        errorText:
          "Для регистрации требуется приглашение. Пожалуйста, свяжитесь с вашим менеджером по аккаунту через {{value}}.",
        errorTextNoEmail:
          "Для регистрации требуется приглашение. Пожалуйста, свяжитесь с вашим менеджером по аккаунту.",
        errorBtn: "Вернуться на домашнюю страницу",
      },
      password: {
        title: "Забыли пароль?",
        text: "Мы отправим вам ссылку для сброса пароля. Пожалуйста, введите адрес электронной почты, который вы используете на платформе.",
        mail: "Email",
        mailInputErr: "Пожалуйста, введите ваш адрес электронной почты.",
        reset: "Сбросить пароль",
        return: "Перейти на страницу входа",
        error:
          "Пожалуйста, введите адрес электронной почты, для которого вы хотите сбросить пароль.",
        check: "Проверьте вашу электронную почту",
        message: "Мы отправили ссылку для сброса пароля на {{value}}",
      },
      newPassword: {
        title: "Создать новый пароль",
        text: "Ваш новый пароль должен отличаться от старых паролей, которые вы использовали.",
        help: "Введите пароль длиной не менее 8 символов, включающий числа, знаки препинания, заглавные и строчные буквы.",
        password: "Пароль",
        passwordInputErr: "Пожалуйста, введите свой пароль.",
        again: "Повторите пароль",
        check: "Сброс пароля успешен",
        message:
          "Теперь вы можете войти, используя новый пароль, который вы установили.",
        errTitle: "Пожалуйста, введите действительный пароль.",
      },
      welcome: {
        title: "Добро пожаловать!",
        loginText:
          "Если у вас есть учетная запись, вы можете ввести свои данные, нажав кнопку входа.",
        loginBtn: "Войти",
        signupText:
          "Если у вас еще нет учетной записи, вы можете нажать на кнопку ниже, чтобы зарегистрироваться, установить свой пароль и затем войти в свою учетную запись.",
        signupBtn: "Зарегистрироваться",
      },
    },
  },
  common: {
    save: "Сохранить",
    selectAll: "Выбрать все",
    search: "Поиск",
    edit: "Редактировать",
    export: "Экспорт",
    remove: "Удалить",
    add: "Добавить",
    column: "Колонка",
    archive: "Архив",
    removeArchive: "Удалить из архива",
    delete: "Удалить",
    filter: "Фильтр",
    continue: "Продолжить",
    cancel: "Отменить",
    cancellation: "Отмена",
    okay: "OK",
    create: "Создать",
    goBack: "Вернуться назад",
    listed: "Перечисленные",
    archived: "Архивировано",
    invited: "Приглашенные",
    exact: "Точное совпадение",
    phrase: "Фразовое совпадение",
    broad: "Широкое совпадение",
    turkey: "Турция",
    abd: "США",
    try: "Турецкие лиры",
    usd: "Доллары США",
    iqd: "Иракский динар",
    twd: "Новый тайваньский доллар",
    hkd: "Гонконгский доллар",
    krw: "Южнокорейская вона",
    jpy: "Японская иена",
    zone: "Европа/Стамбул",
    clear: "Очистить",
    completed: "Завершено",
    notCompleted: "Не завершено",
    now: "Сейчас",
    checkAll: "Выбрать все",
    keyword: "Ключевое слово",
    negative: "Минус-слова",
    tr: "Турецкий",
    en: "Английский",
    day: "День",
    invite: "Пригласить",
    accept: "Принять",
    reject: "Отклонить",
    pageTotal: 'Количество строк для "показа":',
    addUser: "Добавить пользователя",
    reinvite: "Пригласить снова",
    advertiser: "Рекламодатель",
    admin: "Администратор",
    new_customer: "Новые клиенты",
    existing_customer: "Существующие клиенты",
    category_buyer: "Покупатели категорий",
    invite_url: "Посмотреть ссылку на приглашение",
    copy: "Создать копию",
    vastTag: "Получить VAST тег",
    next: "Следующий",
    watch: "Смотреть",
    or: "или",
    exit: "Выйти",
    docs: "Ознакомьтесь с нашей документацией по API, чтобы узнать о интеграции и показе рекламы!",
    apply: "Применить",
    reset: "Сбросить",
    read: "Отметить как прочитанное",
    unread: "Отметить как непрочитанное",
    view: "Просмотреть",
    days: {
      monday: "Понедельник",
      tuesday: "Вторник",
      wednesday: "Среда",
      thursday: "Четверг",
      friday: "Пятница",
      saturday: "Суббота",
      sunday: "Воскресенье",
    },
    table: {
      name: "Название",
      status: "Активен",
      state: "Статус",
      agency_name: "Агентство",
      type: "Тип рекламодателя",
      resource_id: "ID продавца",
      last_login: "Дата последнего входа",
      balance: "Баланс",
      sum_coupons_amount: "Купон",
      total_balance: "Общий баланс",
      impressions: "Показы",
      viewable_impressions: "Просматриваемые показы",
      clicks: "Клики",
      CTR: "Кликабельность (CTR)",
      spend: "Расходы на рекламу",
      sale_amount: "Сумма продаж",
      direct_sale_amount: "Сумма прямых продаж",
      indirect_sale_amount: "Сумма косвенных продаж",
      sale: "Продажа",
      direct_sale: "Прямая продажа",
      indirect_sale: "Косвенная продажа",
      ACoS: "Рекламная стоимость продаж (ACoS)",
      CVR: "Показатель конверсии (CVR)",
      campaign_name: "Название",
      advertiser_name: "Рекламодатель",
      ad_type: "Тип рекламы",
      start_date: "Дата начала",
      end_date: "Дата окончания",
      budget_type: "Бюджет",
      bid_type: "Тип ставки",
      fixed_bid: "Ставка",
      custom_key: "ID",
      ad_format: "Формат рекламы",
      max_ads: "Макс. количество реклам",
      recommended_size: "Рекомендуемый размер",
      sizes: "Размеры",
      image_url: "Креатив",
      policy_status: "Статус",
      transaction_date: "Дата",
      invoice_number: "Номер счета",
      transaction_type: "Тип транзакции",
      description: "Описание",
      created_by: "Создатель",
      amount: "Сумма",
      spend_details: "Детали",
      note: "Примечание",
      expire_date: "Дата истечения срока действия",
      account_user_status: "Активен",
      user_email: "Email",
      account_user_role: "Авторизация",
      user_last_login: "Дата последнего входа",
      account_user_created_at: "Дата создания",
      email: "Email",
      account_role: "Авторизация",
      created_at: "Дата приглашения",
      report_name: "Название",
      report_start_date: "Дата начала",
      report_end_date: "Дата окончания",
      report_type: "Тип отчета",
      report_delivery_frequency: "Частота",
      report_created_at: "Дата создания",
      text: "Название",
      minimum_bid: "Мин. цена за клик (CPC)",
      minimum_cpm: "Мин. цена за тысячу показов (CPM)",
      relevance: "Релевантность",
      restriction_type: "Тип ограничения",
      row_count: "Количество строк",
      message: "Сообщение об операции",
      sync_type: "Тип синхронизации",
      file_name: "Имя файла",
      product: "Товар",
      sku: "Единица складского учёта (SKU)",
      buybox: "Buybox",
      city: "Город",
      age_range: "Возраст",
      gender: "Пол",
      platform: "Платформа",
      customer: "Клиенты",
      category: "Категория",
      keyword: "Ключевое слово",
      match_type: "Тип соответствия",
      bid: "Ставка",
      category_volume: "Объем показов",
      active: "Активен",
      paused: "Приостановлено",
      invited: "Приглашенные",
      uninvited: "Неприглашенные",
      accepted: "Принятые",
      archived: "Архивировано",
      on: "Активен",
      stopped: "Приостановлено",
      open: "Открыто",
      closed: "Закрыто",
      coupon: "Купон",
      sync_log_created_at: "Дата",
      category_text: "Категория",
      keyword_text: "Ключевое слово",
      creative_name: "Название",
      invoice: "Номер счета",
      acos: "Рекламная стоимость продаж (ACoS)",
      sum_click: "Клики",
      sum_spend: "Расходы на рекламу",
      sum_impression: "Показы",
      sum_viewable_impression: "Просматриваемые показы",
      sum_sale: "Продажа",
      sum_direct_sale: "Прямая продажа",
      sum_indirect_sale: "Косвенная продажа",
      sum_direct_sale_amount: "Сумма прямых продаж",
      sum_indirect_sale_amount: "Сумма косвенных продаж",
      sum_sale_amount: "Сумма продаж",
      invite_status: "Статус приглашения",
      spending_type: "Распределение расходов",
      min_ads: "Мин. количество объявлений",
      min_bid: "Мин. ставка",
      languages: "Язык",
      video_start: "Запуски видео",
      video_complete: "Завершения видео",
      VCR: "VCR",
      entity: "Сущность",
      import_method: "Метод импорта",
      schedule_interval: "Цикл",
      finish_date: "Дата завершения",
      id: "ID",
      uuid: "UUID",
      updated_by: "Обновлено",
    },
    equality: {
      contains: "Содержит",
      not_contains: "Не содержит",
      equal: "Равно",
      not_equal: "Не равно",
      less_than: "Меньше",
      grater_than: "Больше",
      greater_or: "Больше или",
      less_or: "Меньше или",
      any_of: "Любое из",
      none_of: "Ни одно из",
    },
    filters: {
      campaignName: "Название",
      campaignStartDate: "Дата начала",
      campaignEndDate: "Дата окончания",
      campaignStatus: "Статус",
      campaignBudget: "Бюджет",
      campaignBudgetType: "Тип бюджета",
      campaignBidType: "Тип ставки",
      campaignMaxAcos: "Максимальная рекламная стоимость продаж (ACoS)",
      campaignFixedBid: "Фиксированная ставка",
      sumSale: "Продажа",
      sumSpend: "Расходы",
      ACoS: "Рекламная стоимость продаж (ACoS)",
      product: "Товар",
      productCampaignStatus: "Статус",
      sumClick: "Клики",
      CVR: "Показатель конверсии (CVR)",
      keywordCampaignStatus: "Статус",
      keywordText: "Ключевое слово",
      keywordMatchType: "Тип соответствия",
      negativeKeywordCampaignStatus: "Статус",
      negativeKeywordText: "Минус-слова",
      negativeKeywordMatchType: "Тип соответствия",
      cityName: "Город",
      cityCampaignStatus: "Статус",
      ageRangeText: "Возраст",
      ageRangeCampaignStatus: "Статус",
      genderText: "Пол",
      genderCampaignStatus: "Статус",
      platformText: "Платформа",
      platformCampaignStatus: "Статус",
      customerTargetingText: "Клиент",
      customerTargetingCampaignStatus: "Статус",
      placementText: "Рекламное размещение",
      placementCampaignStatus: "Статус",
      reportName: "Название отчета",
      reportStartDate: "Дата начала",
      reportEndDate: "Дата окончания",
      reportType: "Тип отчета",
      reportDeliveryFrequency: "Частота",
      reportCreatedAt: "Дата создания",
      agencyName: "Название агентства",
      advertiserName: "Рекламодатель",
      advertiserStatus: "Статус рекламодателя",
      sumSaleAmount: "Сумма продаж",
      sumDirectSale: "Прямая продажа",
      sumIndirectSale: "Косвенная продажа",
      sumDirectSaleAmount: "Сумма прямых продаж",
      sumIndirectSaleAmount: "Сумма косвенных продаж",
      sumImpression: "Показы",
      productBrand: "Бренд товара",
      CTR: "Кликабельность (CTR)",
      keyword: "Ключевое слово",
      category: "Категория",
      advertiserId: "ID рекламодателя",
      agencyId: "ID агентства",
      name: "Название товара",
      brand: "Бренд",
      retailer_taxonomy: "Категория товара",
      advertiser_id: "ID рекламодателя",
      campaignId: "ID кампании",
    },
    equalityValues: {
      ct: "Содержит",
      nc: "Не содержит",
      eq: "Равно",
      ne: "Не равно",
      in: "Любое из",
      ni: "Ни одно из",
      notin: "Ни одно из",
      lt: "Меньше",
      gt: "Больше",
      ge: "Больше или",
      le: "Меньше или",
    },
    values: {
      ACTIVE: "Активен",
      PAUSED: "Приостановлено",
      DAILY: "Ежедневный",
      TOTAL: "Итоговый",
      STANDART: "Авто",
      FIXED: "Фиксированная",
      MAX_ACOS: "Макс. рекламная cтоимость продаж (ACoS)",
      WEEKLY: "Еженедельный",
      MONTHLY: "Ежемесячный",
      EXACT: "Точное совпадение",
      PHRASE: "Фразовое совпадение",
      BROAD: "Широкое совпадение",
      BALANCE_UPDATED: "Пополнение баланса",
      GIFT_COUPON: "Подарочный купон",
      WELCOME_COUPON: "Приветственный купон",
      SPEND: "Расходы на рекламу",
      NOT_STARTED: "Кампания еще не началась.",
      DATE_PASSED:
        "Кампания завершена, так как достигла запланированной даты окончания.",
      STATUS_PAUSED: "Вы поставили кампанию на паузу.",
      OUT_OF_DAILY_BUDGET: "Дневной бюджет вашей кампании исчерпан.",
      OUT_OF_TOTAL_BUDGET: "Ваша кампания достигла общего лимита бюджета.",
      PRODUCTS_STOPPED: "Продукты не активны.",
      KEYWORDS_STOPPED: "Ключевые слова не активны.",
      CATEGORIES_STOPPED: "Категории не активны.",
      STATUS_ARCHIVED: "Вы архивировали кампанию.",
      CREATIVES_STOPPED: "Нет активных креативов.",
      INSUFFICIENT_BALANCE: "Недостаточный баланс.",
      LOW_BID: "Ставка ниже минимально допустимой.",
      ADVERTISER_PAUSED: "Рекламодатель не активен.",
      REGIONS_STOPPED: "Регионы не активны.",
      PRODUCT: "Спонсируемые товары",
      BRAND: "Спонсируемые бренды",
      DISPLAY: "Спонсируемый показ",
      STORE: "Спонсируемый магазин",
      VIDEO: "Спонсируемое видео",
      DISPLAY_WITH_PRODUCT: "Спонсируемый брендовый дисплей",
      VIDEO_WITH_PRODUCT: "Спонсируемый брендовый видеоролик",
    },
    month: {
      may: "Май",
      june: "Июнь",
      september: "Сентябрь",
      october: "Октябрь",
      december: "Декабрь",
    },
  },
};

export default ru;
